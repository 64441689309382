import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import {Main} from "./meetingsStyles";
import {ListGroup} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import {useWindowSize} from "../../useWindowSize";
import {Message} from "semantic-ui-react";
import LoaderOverLayAntD from "../LoaderOverLayAntD";
import {Button, message, Popconfirm} from "antd";
import {useSelector} from "react-redux";
import {getDynamicStyleNoPadding} from "../Common/Styles";
import PageTitle from "../Common/PageTitle";


const ASSHMeetingsComponent = () => {

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const admin = useSelector(state => state.admin);

  useEffect(() => {
    setLoading(true)

    async function fetchData() {
      const meetings = await axios.get('/meetingASSH/list');
      setState(meetings.data);
    }

    fetchData().then(() => {
      setLoading(false)
    });

  }, [])

  const {width} = useWindowSize();

  const removeMeeting = (id) => {
    axios.post('/meetingASSH/delete', {
      id
    }).then(r => {

      message.success('Meeting successfully deleted ', 2)
        .then(() =>
          message.info("Page will be refreshed", 2))
        .then(() => {
          window.location.reload(false);
        });

    }).catch(e => {
      console.log(e)
    });
  }

  const user = useSelector(state => state.user);

  return (<>
      <PageTitle title={'ASSH Annual Meeting'}/>
      <div style={Main}>

        {loading ? <LoaderOverLayAntD/> : <>

          {state.length === 0 ?
            <Message warning style={
              getDynamicStyleNoPadding(width)
            } header={'No Events'}/> : <>

              {state.map((m, index) =>
                <div key={index}>
                  <Card style={getDynamicStyleNoPadding(width)}>
                    {/*<Card.Img  variant="top" src="./Logo_400px.jpg"/>*/}
                    <Card.Body>
                      <Card.Title> {m.title}

                        {Object.values(admin).includes(user.email)&&
                          <span style={{float: 'right'}}>
                        <Popconfirm
                          title="Delete meeting?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => removeMeeting(m._id)}
                        >
                         <Button type="primary" size={'small'} danger>delete</Button>
                        </Popconfirm>
                      </span>}

                      </Card.Title>

                    </Card.Body>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item><b>Where:</b> {m.location}</ListGroup.Item>
                      <ListGroup.Item><b>When:</b> {m.date}</ListGroup.Item>
                      <ListGroup.Item><b>Description:</b> {m.otherComments}</ListGroup.Item>

                    </ListGroup>
                    <Card.Body>
                      <Card.Link href="#" as={Link} to={'/register-assh'}
                                 state={{ description: m.title}}>Register</Card.Link>
                    </Card.Body>
                  </Card>
                </div>
              )}

            </>
          }
        </>
        }
      </div>
    </>
  )
}

export default ASSHMeetingsComponent;