export const MainGlobal = {
  maxWidth: 400,
  margin: 'auto',
  padding: 30,
  border: '1px solid #f4f4f4',
  boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)"
}

export const getDynamicStyle = (width) => (
  width > 600 ?
    {
      width: 600,
      margin: "auto",
      marginTop: 78,
      boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
      padding: '0px 10px 0px 10px',
      borderRadius: 6,
      border: '#f4f4f4 solid .5px'

    } :
    {
      width: width - 10,
      margin: "auto",
      marginTop: 78,
      boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
      padding: '0px 10px 0px 10px',
      borderRadius: 6,
      border: '#f4f4f4 solid .5px'
    }
);

export const getDynamicStyleNoPadding = (width) => (
  width > 600 ?
    {
      width: 600,
      margin: "auto",
      marginTop: 8,
      boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
      borderRadius: 6,
      border: '#f4f4f4 solid .5px'

    } :
    {
      width: width - 10,
      margin: "auto",
      marginTop: 8,
      boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
      borderRadius: 6,
      border: '#f4f4f4 solid .5px'
    }
);