import React, {useEffect, useState} from 'react'
import {Button, Message} from 'semantic-ui-react'
import {defaultColor, selectedColor} from "../Common/Defaults";

import {useSelector} from "react-redux";
import LoginForm from "../Auth/LoginForm";
import MemberDirectory from "../Members/MemberDirectory";
import TriennialRegistration from "../Members/TriennialRegistration";
import {useWindowSize} from "../../useWindowSize";
import PaymentDuesButtonsChoice from "../Payments/PaymentDuesButtonsChoice";
import {useLocation} from "react-router-dom";

const MembersMenu = () => {

  const [active, setActive] = useState(null);

  const userState = useSelector(state => state.user);
  const member = useSelector(state => state.member);
  const location = useLocation();

  useEffect(() => {
    if (location.state === null) {
      setActive('1');
    } else {
      if (location.state)
        setActive(location.state.active)
    }
  }, [userState,location.state]);


  function handleClick(e) {
    setActive(e.target.id)
  }

  const styleDefault = {color: 'white', backgroundColor: defaultColor.backgroundColor};
  const styleSelected = {color: 'white', backgroundColor: selectedColor.backgroundColor};
  const {width} = useWindowSize();
  const admin = useSelector(state => state.admin);

  return (

    <>
      <>
        {(member.isSignedIn || (Object.values(admin).includes(userState.email))) ? <>

            <div className={'menu-members'} style={
              width > 1000 ?
                {width: 1000, margin: "auto"} : {width: width - 2, margin: "auto"}
            }>

              <Button.Group widths={'6'} size={'small'}>
                <Button id={'1'} onClick={handleClick}
                        style={active === '1' ? styleSelected : styleDefault}>Directory</Button>
                <Button id={'2'} onClick={handleClick}
                        style={active === '2' ? styleSelected : styleDefault}>Annual Dues</Button>
                <Button id={'3'} onClick={handleClick}
                        style={active === '3' ? styleSelected : styleDefault}>Triennial
                  Registration</Button>
              </Button.Group>


            </div>

            {active === '1' && <MemberDirectory/>}
            {active === '2' && <PaymentDuesButtonsChoice/>}
            {active === '3' && <TriennialRegistration/>}

          </> :
          <>
            <div><Message warning style={{
              marginTop: 80,
              marginBottom: -100,
              marginLeft: "auto",
              color: "#d937d9",
              maxWidth: '100%'
            }}>
              Accessible only for members
            </Message>

              {!userState.isSignedIn && <LoginForm/>}

            </div>
          </>

        }
      </>
    </>
  )
}


export default MembersMenu;