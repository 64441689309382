import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
// import ReduxThunk from 'redux-thunk';
// import {createStore, applyMiddleware, combineReducers} from 'redux';
import userReducer from "./redux/user/user.reducer";
import {meetingsReducer} from "./redux/meeting/meetings.reducer";
import {registerReducer} from "./redux/meetingRegister/register.reducer";
import videoReducer from "./redux/video/video.reducer";
import searchBoxReducer from "./redux/searchBox/searchBox.reducer";
import {configureStore} from '@reduxjs/toolkit'
import {createRoot} from 'react-dom/client';
import {fetchMembersReducer} from "./redux/fetchMembers/fetchMembers.reducer";
import memberReducer from "./redux/member/member.reducer";
import adminReducer from "./redux/admin/admin.reducer";


// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    user: userReducer,
    meetings: meetingsReducer,
    register: registerReducer,
    video: videoReducer,
    search: searchBoxReducer,
    fetchMembers: fetchMembersReducer,
    member: memberReducer,
    admin: adminReducer
  }
})

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <App/>
  </Provider>,
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
