import {DatePicker, Input, message, Popconfirm} from "antd";
import React, {useState} from "react";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import {Button} from "@aws-amplify/ui-react";

import {useSelector} from "react-redux";
import {useWindowSize} from "../../useWindowSize";


const AddTriennial = () => {
  const [img, setImg] = useState("");

  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({
    title: '',
    location: '',
    date: '',
    price: 0,
    otherCosts: 0,
    otherCostsDescription: '',
    otherComments: '',
  });
  const updateImage = (e) => {
    setImg(e.target.files[0]);
  }

  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  function onChangeAmount(e) {
    e.persist();
    if (!e.target.validity.patternMismatch) {
      setState({...state, price: e.target.value});
    }
  }

  function onChangeAmountOtherCosts(e) {
    e.persist();
    if (!e.target.validity.patternMismatch) {
      setState({...state, otherCosts: e.target.value});
    }
  }


  const handleUpload = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", img);
    data.append("upload_preset", 'rridxch3');
    data.append("cloud_name", 'duke-hand-society');
    // data.append("folder","your-folder-name");


    axios.post(`https://api.cloudinary.com/v1_1/duke-hand-society/image/upload`, data).then(r => {

      axios.post('/triennial',
        {
          data: {
            title: state.title,
            location: state.location,
            date: state.date,
            price: state.price,
            otherCosts: state.otherCosts,
            otherCostsDescription: state.otherCostsDescription,
            otherComments: state.otherComments,
            url: r.data.secure_url
          }
        })
        .then((e) => {
          // console.log(e.data)
        }).then(() => {

        message.success('Triennial successfully created', 2)
          .then(() =>
            message.info("Page will be refreshed", 2))
          .then(() => {
            window.location.reload();
          }).then(() => {
        });

      })
        .catch(e => {
          console.log(e)
        });

    }).catch(err => {
      console.log(err)
    })
  }


  const dateFormat = 'YYYY/MM/DD';
  const userState = useSelector(state => state.user);
  const {width} = useWindowSize();
  const admin = useSelector(state => state.admin);

  return (
    <>
      {Object.values(admin).includes(userState.email) &&
        <div style={
          width > 600 ?
            {
              width: 600, margin: "auto",
              padding: '0px 10px 0px 10px',
              borderRadius: 6,
            } : {
              width: width - 15, margin: "auto",
              padding: '0px 10px 0px 10px',
              borderRadius: 6,
            }
        }>
          <form style={{marginTop: 20}}>
            <div className={'hover-border'}>
              <h1>New Triennial</h1>

              <h4 style={{color: '#b2b1b1'}}>Image</h4>
              <input
                style={stylesInput}
                type="file"
                onChange={updateImage}
                className="form-control shadow-sm"
                id="image"
                name="image"
                accept="image/*"
              />
              <Input
                style={stylesInput}
                value={state.title}
                name={'title'}
                placeholder={'Title'}
                onChange={updateField}

              />


              <Input
                style={stylesInput}
                name={'location'}
                onChange={updateField}
                placeholder={'Location'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />

              <DatePicker
                showTime={false}
                onChange={(e) => setState({
                  ...state,
                  date: e.$d.toDateString()
                })}
                style={stylesInput}
                format={dateFormat}
              />

              <Input
                style={stylesInput}
                value={state.price || ''}
                pattern="^-?[0-9]\d*\.?\d*$"
                placeholder={'Price'}
                onChange={onChangeAmount}
              />

              <Input
                style={stylesInput}
                value={state.otherCosts || ''}
                pattern="^-?[0-9]\d*\.?\d*$"
                placeholder={'Other Costs'}
                onChange={onChangeAmountOtherCosts}
              />
              <Input
                style={stylesInput}
                value={state.otherCostsDescription}
                name={'otherCostsDescription'}
                placeholder={'Other Costs Description'}
                onChange={updateField}

              />


              <TextArea
                style={stylesInput}
                name={'otherComments'}
                placeholder={"Description"}
                onChange={updateField}
              />
              <Popconfirm placement="top" title={"Free of errors?"}
                          onConfirm={handleUpload}
                          okText="Yes" cancelText="No">

                <Button
                  variation="primary"
                  colorTheme="info"
                  type="submit"
                  onClick={event => event.preventDefault()}
                >
                  Create
                </Button>

              </Popconfirm>
            </div>
          </form>
        </div>}
    </>)
}

export default AddTriennial;