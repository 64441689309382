import React, {useState} from "react";
import {Button, Input, message} from "antd";
import {Message} from "semantic-ui-react";
import axios from "axios";

const RecurringPaymentLinkUs = () => {

  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({
    url: '',
    id: ''
  });


  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    axios.post('/payment-link-recurring',
      {
        data: state
      })
      .then((e) => {
        // console.log(e.data)
      }).then(() => {

      message.success('Link successfully created', 2)
        .then(() =>
          message.info("Page will be refreshed", 2))
        .then(() => {
          window.location.reload();
        }).then(() => {
      });

    })
      .catch(e => {
        console.log(e)
      });


  }


  return (
    <>
      <div style={
        {maxWidth:500,margin:"auto", marginTop:10}
      }>
      <Message
      warning
      header='Add the payment link (no spaces)'
      content='Us Member recurring payment '
    />
      <Input
        style={stylesInput}
        name={'url'}
        onChange={updateField}
        placeholder={'recurring payment membership url'}
        rules={[
          {
            required: true,
          },
        ]}
      />
      <Button type="primary" ghost onClick={handleSubmit}>
        Save
      </Button></div>
    </>
  )
}

export default RecurringPaymentLinkUs;
