import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import {BrowserRouter as Router} from "react-router-dom";
import NavTitle from "./NavTitle";
import HorizontalLinks from "./HorizontalLinks";
import RoutesComponent from "./RoutesComponent";
import { dukeColor} from "../Common/Defaults";
// import {Menu, message, Modal} from "antd";
// import {useWindowSize} from "../../useWindowSize";
// 
// import {useSelector, useDispatch} from "react-redux";
// import {signOut} from 'aws-amplify/auth';
// import {signOutUser} from "../../redux/user/user.actions";
// import {signOutMember} from "../../redux/member/member.actions";

// const Label = ({url, name}) => {
//   return <>
//     <Link style={{textDecoration: "none", fontWeight: 500}} to={url}>{name}</Link>
//   </>
// }

const MainMenu = () => {
  //
  const expand = 'sm';
  // const {width} = useWindowSize();
  // const [current, setCurrent] = useState('0');
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const dispatch = useDispatch();
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };
  // const user = useSelector((state => state.user));
  // const items = [
  //   {
  //     label: <><Label name={'About'} url={'/about'}/></>,
  //     key: '1'
  //   },
  //   {
  //     label: <><Label name={'Contact'} url={'/contact'}/></>,
  //     key: '2',
  //   },
  //   {
  //     label: 'Members',
  //     children: [
  //       {
  //         type: 'group',
  //
  //         children: [
  //           {
  //             label: <>
  //               <Label
  //                 name={'Members Directory'}
  //                 url={'/members-directory'}/>
  //             </>,
  //             key: '3'
  //           },
  //           {
  //             label: <>
  //               <Label
  //                 name={'Annual Dues'}
  //                 url={'/dues-choice'}/>
  //             </>,
  //             key: '4'
  //           },
  //           {
  //             label: <>
  //               <Label
  //                 name={'Triennial Registration'}
  //                 url={'/triennial'}/>
  //             </>,
  //             key: '5',
  //           },
  //         ],
  //       },
  //
  //     ],
  //   },
  //   {
  //     label: 'Residents',
  //     key: '7',
  //   },
  //   {
  //     label: (
  //       user.isSignedIn && <span style={{color: "darkorange"}} onClick={showModal}>Logout</span>
  //     ),
  //     key: '8',
  //   },
  //   {
  //     label: (
  //       (IDAdmin.includes(user.id)) && <span style={{color: "red"}}>
  //         <>
  //           <Label
  //             name={'Admin'}
  //             url={'/admin-page'}/>
  //         </>
  //       </span>
  //     ),
  //     key: '9',
  //   },
  // ];

  // async function handleSignOut(e) {
  //   e.preventDefault()
  //   try {
  //     await signOut().then(() => {
  //       setIsModalOpen(false)
  //       message.info('You are signed out ' + user.email);
  //       dispatch(signOutUser())
  //       dispatch(signOutMember())
  //     });
  //
  //   } catch (error) {
  //     console.log('error signing out: ', error);
  //   }
  // }

  return (

    <Router>
      {/*<div className={"fixed-top"} style={{backgroundColor: dukeColor.backgroundColor}}>*/}
      {/*  <h1*/}
      {/*    style={width > 600 ?*/}
      {/*      {fontFamily: '"EB Garamond", sans-serif', color: "white", marginLeft: 20} :*/}
      {/*      {textAlign: "center", fontFamily: '"EB Garamond", sans-serif', color: "white"}*/}
      {/*    }>*/}
      {/*    /!*<span><img src="/LogoRound.png" style={{marginLeft:10}} alt="Logo" className={"logo"}/></span>*!/*/}
      {/*    <span>Duke Hand Society</span>*/}
      {/*  </h1>*/}
      {/*  <Menu onClick={(e) => setCurrent(e.key)} mode="horizontal" selectedKeys={[current]} items={items}/>*/}
      {/*</div>*/}

      {/*<>*/}
      {/*  <Modal title={<><h3>Log Out </h3>{user.email}?</>}*/}
      {/*         open={isModalOpen}*/}
      {/*         onOk={handleSignOut}*/}
      {/*         onCancel={handleCancel}>*/}
      {/*  </Modal>*/}
      {/*</>*/}


      <Navbar collapseOnSelect expand="sm" fixed={'top'} className={'py-0'}
              style={{backgroundColor: dukeColor.backgroundColor}}
              variant={'dark'}>

        <Container>
          <div style={{
            background: '#e2e6ed'
            , position: "fixed", top: 74.5, left: 0, right: 0
            , height: 2
          }}/>

          <NavTitle/>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}/>

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <HorizontalLinks expand={expand}/>
          </Navbar.Offcanvas>

        </Container>
      </Navbar>
      <RoutesComponent/>
    </Router>

  );
}


export default MainMenu;