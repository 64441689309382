import NavDropdown from "react-bootstrap/NavDropdown";
import React from "react";
import { useNavigate} from "react-router-dom";
import {Icon} from "semantic-ui-react";

const AdminLInk = () => {

const navigate  = useNavigate();
  return(

  <NavDropdown.Item href="#" onClick={()=>{navigate('/admin-page')}} style={{color:'red'}}><Icon name={'cogs'}/> ADMIN
    {/*<NavDropdown.Item  as={Link} to="/add-member" href="#add-member" >Add Member</NavDropdown.Item>*/}
    {/*<NavDropdown.Item  as={Link} to="/add-meeting" href="#add-event">Add Event</NavDropdown.Item>*/}
    {/*<NavDropdown.Item href="#upload-doc">Upload Document</NavDropdown.Item>*/}
  </NavDropdown.Item>

)}

export default AdminLInk
