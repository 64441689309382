import React from "react";
import {Spin} from "antd";

export default function LoaderOverLayAntD() {
  return <div
    style={{
      zIndex: 20,
      position: "fixed",
      top: 0, left: 0,
      backgroundColor:
        "#e1e1e170",
      width: window.innerWidth,
      height: window.innerHeight
    }}
  >
    <h1 style={{
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)'
    }}
    ><Spin size={'large'}/></h1></div>;
}
