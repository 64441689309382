import React from "react";
import {Amplify} from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {Hub} from "aws-amplify/utils";
import {Button} from "react-bootstrap";
import {signInUser, signOutUser} from "../../redux/user/user.actions";
import Container from "react-bootstrap/Container";

import {configLogin} from "../../Config/configLogin";
import {signInMember, signOutMember} from "../../redux/member/member.actions";
import axios from "axios";

// import awsExports from "./aws-exports";

// ./aws-exports cannot be found by heroku thus directly
// put to  Amplify.configure


Amplify.configure(configLogin);
//aws acount is at pavlospapa...@yahoo.com


export default function LoginForm() {

  const admin = useSelector(state => state.admin);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  Hub.listen('auth', (
    {payload}) => {
    if (payload.event === 'signedIn') {// console.log('user have been signedIn successfully.');
      const user = {
        id: payload.data.userId,
        email: payload.data.signInDetails.loginId
      }
      dispatch(signInUser(user));

      if (Object.values(admin).includes(user.email)) {
        navigate('/admin-page')
        return
      }

      axios.get('/member')
        .then(members => {

          Object.entries(members.data).forEach(([key, member]) => {
            if (member.email === user.email.toString()) {

              const currentMember = {
                id: member._id,
                key: key,
                lastName: member.lastName,
                firstName: member.firstName,
                suffix: member.suffix,
                email: member.email
              }


              dispatch(signInMember({
                id: currentMember.id,
                email: currentMember.email
              }));
              navigate('/members');
            }
          });
        });


    } else if (payload.event === 'signedOut') {// console.log('user have been signedOut successfully.');
      dispatch(signOutUser())
      dispatch(signOutMember())
    }
  });


  return (
    <>
      <div className={"main"}>
        <div>
          <Authenticator
            variation="default"
            loginMechanisms={['email']}>

            {({signOut, user}) =>
              <>
                <Container fluid={true} className={'m-3'}>You are currently logged in
                  {/*<h3>{user.signInDetails.loginId}</h3>*/}
                  {Object.values(admin).includes(user.email) ?
                    <span style={{color: 'red'}}>( as admin ){user.email}</span> :
                    <span style={{color: 'blueviolet'}}> {user.email}</span>}
                </Container>

                <h1 className={'m-4'}>
                  <Button variant="outline-primary" onClick={signOut}>Sign out</Button>
                </h1>
              </>
            }

          </Authenticator></div>

      </div>
    </>
  );
}
