import {Button, message, Table} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";

const ASSHRegisterUsers = () => {

  const userState = useSelector(state => state.user);
  const columns = [
    {
      title: 'Firstname',
      dataIndex: 'firstName',
    },
    {
      title: 'LastName',
      dataIndex: 'lastName',
    },
    {
      title: 'Suffix',
      dataIndex: 'suffix',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),

    }

  ];
  const [currentRegistered, setCurrentRegistered] = useState([]);
  const admin = useSelector(state => state.admin);

  useEffect(() => {
    const arr = [];

    axios.get('/assh-register')
      .then(r => {

        Object.entries(r.data).forEach(([key, d]) => {

          arr.push({
            id: d._id,
            key: key,
            lastName: d.lastName,
            firstName: d.firstName,
            suffix: d.suffix,
            email: d.email,
            createdAt: new Date(d.createdAt).toDateString()
          });
        });
      }).then(() => {
      setCurrentRegistered(arr);
    });

  }, []);
  const deleteAll = async () => {
    axios.delete('/assh-register').then((r) => {

      message.success("Successfully deleted", 2)
        .then(() =>
          message.info("Page will be refreshed", 2))
        .then(() => {
          window.location.reload(false);
        });
    }).catch(() => {
      message.error('An error occurred')
    });

  }

  return <>

    {Object.values(admin).includes(userState.email) &&
      <Table
        bordered
        title={() => <h3>ASSH Registered users</h3>}
        columns={columns}
        dataSource={currentRegistered}
        footer={() => currentRegistered.length > 0 ? <Button danger onClick={deleteAll}>Delete all </Button> : null}
      />
    }
  </>
}
export default ASSHRegisterUsers;