import {Input, message, Popconfirm} from "antd";
import React, {useState} from "react";
import axios from "axios";
import {Button} from "@aws-amplify/ui-react";

import {useSelector} from "react-redux";
import {useWindowSize} from "../../useWindowSize";

const AddMember = () => {

  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }
  const admin = useSelector(state => state.admin);

  const [state, setState] = useState({
    memberImage: '',

    officeAddressStreet: '',
    officeAddressCity: '',
    officeAddressSuite: '',
    officeAddressState: '',
    officeTelephone: '',
    officeWebLink: '',

    lastName: '',
    firstName: '',
    suffix: '',
    email: '',
    mobilePhone: '',
    specialty: '',
    practiceType: '',
    joinDate: '',
    memberType: '',
    spouseName: ''
  });


  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };


  const handleUpload = async (e) => {
    e.preventDefault();

    if (img === "") {
      axios.post('/member',
        {
          data: {
            // memberImage: "https://res.cloudinary.com/djagznbnb/image/upload/v1722540721/avatar.png",
            memberImage: "./avatar.png",
            officeAddressStreet: state.officeAddressStreet,
            officeAddressCity: state.officeAddressCity,
            officeAddressSuite: state.officeAddressSuite,
            officeAddressState: state.officeAddressState,
            officeTelephone: state.officeTelephone,
            officeWebLink: state.officeWebLink,
            lastName: state.lastName,
            firstName: state.firstName,
            suffix: state.suffix,
            email: state.email,
            mobilePhone: state.mobilePhone,
            specialty: state.specialty,
            practiceType: state.practiceType,
            joinDate: state.joinDate,
            memberType: state.memberType,
            spouseName: state.spouseName
          }
        })
        .then((e) => {
          // console.log(e.data)
        }).then(() => {

        message.success('Member successfully created', 2)
          .then(() =>
            message.info("Page will be refreshed", 2))
          .then(() => {
            window.location.reload();
          }).then(() => {
        });

      });


    } else {


      const data = new FormData();
      data.append("file", img);
      data.append("upload_preset", 'rridxch3');
      data.append("cloud_name", 'duke-hand-society');
      // data.append("folder","your-folder-name");

      // initialMembers.forEach(m => {
      //   axios.post('/member',
      //     {
      //       data: {
      //         memberImage: '',
      //         officeAddressStreet: '',
      //         officeAddressCity: '',
      //         officeAddressSuite: '',
      //         officeAddressState: '',
      //         officeTelephone: '',
      //         officeWebLink: '',
      //         lastName: m["Member Last Name"],
      //         firstName: m["Member First Name"],
      //         suffix: m["Suffix"],
      //         email: m["Email"],
      //         mobilePhone: '',
      //         specialty: '',
      //         practiceType: '',
      //         joinDate: '',
      //         memberType: '',
      //         spouseName: ''
      //       }
      //     })
      //     .then((e) => {
      //       // console.log(e.data)
      //     }).then(() => {
      //
      //     message.success('Member successfully created', 2)
      //       .then(() =>
      //         message.info("Page will be refreshed", 2))
      //       .then(() => {
      //         window.location.reload();
      //       }).then(() => {
      //     });
      //
      //   })
      //     .catch(e => {
      //       console.log(e)
      //     });
      // })


      axios.post(`https://api.cloudinary.com/v1_1/duke-hand-society/image/upload`, data)
        .then(r => {

          axios.post('/member',
            {
              data: {
                memberImage: r.data.secure_url,
                officeAddressStreet: state.officeAddressStreet,
                officeAddressCity: state.officeAddressCity,
                officeAddressSuite: state.officeAddressSuite,
                officeAddressState: state.officeAddressState,
                officeTelephone: state.officeTelephone,
                officeWebLink: state.officeWebLink,
                lastName: state.lastName,
                firstName: state.firstName,
                suffix: state.suffix,
                email: state.email,
                mobilePhone: state.mobilePhone,
                specialty: state.specialty,
                practiceType: state.practiceType,
                joinDate: state.joinDate,
                memberType: state.memberType,
                spouseName: state.spouseName
              }
            })
            .then((e) => {
              // console.log(e.data)
            }).then(() => {

            message.success('Member successfully created', 2)
              .then(() =>
                message.info("Page will be refreshed", 2))
              .then(() => {
                window.location.reload();
              }).then(() => {
            });

          })
            .catch(e => {
              console.log(e)
            });

        }).catch(err => {
        console.log(err)
      });

    }
  }

  const {width} = useWindowSize();

  const userState = useSelector(state => state.user);
  const [img, setImg] = useState("");

  const updateImage = (e) => {
    setImg(e.target.files[0]);
  }


  return (
    <>
      {Object.values(admin).includes(userState.email) &&
        <div style={
          width > 600 ?
            {width: 600, margin: "auto"} : {width: width - 15, margin: "auto"}
        }>
          <form style={{marginTop: 20}}>
            <div>
              <h1>New member</h1>

              <h5 style={{color: "#b4b4b4"}}>Member Image (drag image or click to choose) </h5>
              <input
                style={stylesInput}
                type="file"
                onChange={updateImage}
                className="form-control shadow-sm"
                id="image"
                name="image"
                accept="image/*"
              />
              <Input
                style={stylesInput}
                name={'lastName'}
                placeholder={'Last Name'}
                onChange={updateField}
              />
              <Input
                style={stylesInput}
                name={'firstName'}
                onChange={updateField}
                placeholder={'First Name'}
              />
              <Input
                style={stylesInput}
                name={'suffix'}
                onChange={updateField}
                placeholder={'Suffix'}
              />
              <Input
                style={stylesInput}
                name={'email'}
                onChange={updateField}
                placeholder={'email'}
              />

              <Input
                style={stylesInput}
                name={'officeAddressStreet'}
                onChange={updateField}
                placeholder={'Office Street Address'}
              />
              <Input
                style={stylesInput}
                name={'officeAddressCity'}
                onChange={updateField}
                placeholder={'Office City Address'}
              />
              <Input
                style={stylesInput}
                name={'officeAddressSuite'}
                onChange={updateField}
                placeholder={'Office Suite Number'}
              />
              <Input
                style={stylesInput}
                name={'officeAddressState'}
                onChange={updateField}
                placeholder={'Office Address State'}
              />
              <Input
                style={stylesInput}
                name={'officeTelephone'}
                onChange={updateField}
                placeholder={'Office Telephone'}
              />
              <Input
                style={stylesInput}
                name={'officeWebLink'}
                onChange={updateField}
                placeholder={'Office WebLink'}
              />
              <Input
                style={stylesInput}
                name={'mobilePhone'}
                onChange={updateField}
                placeholder={'Mobile Phone'}
              />
              <Input
                style={stylesInput}
                name={'specialty'}
                onChange={updateField}
                placeholder={'Specialty'}
              />
              <Input
                style={stylesInput}
                name={'practiceType'}
                onChange={updateField}
                placeholder={'Practice Type'}
              />
              <Input
                style={stylesInput}
                name={'joinDate'}
                onChange={updateField}
                placeholder={'Join Date'}
              />
              <Input
                style={stylesInput}
                name={'memberType'}
                onChange={updateField}
                placeholder={'Member Type'}
              />
              <Input
                style={stylesInput}
                name={'spouseName'}
                onChange={updateField}
                placeholder={'Spouse Name'}
              />

              <Popconfirm placement="top" title={"Free of errors?"}
                          onConfirm={handleUpload}
                          okText="Yes" cancelText="No">

                <Button
                  variation="primary"
                  colorTheme="info"
                  type="submit"
                  onClick={event => event.preventDefault()}
                >
                  Create
                </Button>

              </Popconfirm>
            </div>
          </form>
        </div>}
    </>)
}

export default AddMember;


// import {Input, message, Popconfirm} from "antd";
// import React, {useState} from "react";
// import axios from "axios";
// import {Button} from "@aws-amplify/ui-react";
// 
// import {useSelector} from "react-redux";
// import {useWindowSize} from "../../useWindowSize";
//
// const AddMember = () => {
//
//   const stylesInput = {
//     marginBottom: 10, padding: 10, borderRadius: 5,
//     width: '-webkit-fill-available'
//   }
//
//   const [state, setState] = useState({
//     lastName: '',
//     firstName: '',
//     suffix: '',
//     email: '',
//   });
//
//
//   const updateField = e => {
//     e.persist();
//     setState({
//       ...state,
//       [e.target.name]: e.target.value
//     });
//   };
//
//   async function confirm() {
//
//     await axios.post('/member',
//       {
//         data: state
//       })
//       .then((e) => {
//         // console.log(e.data)
//       }).then(() => {
//
//         message.success('Member successfully created', 2)
//           .then(() =>
//             message.info("Page will be refreshed", 2))
//           .then(() => {
//             window.location.reload();
//           }).then(() => {
//         });
//
//       })
//       .catch(e => {
//         console.log(e)
//       });
//   }
//
//   const {width} = useWindowSize();
//
//   const userState = useSelector(state => state.user);
//
//   return (
//     <>
//       {IDAdmin.includes(userState.id) &&
//         <div style={
//           width > 600 ?
//             {width: 600, margin: "auto"} : {width: width - 15, margin: "auto"}
//         }>
//           <form style={{marginTop: 20}}>
//             <div>
//               <h1>New member</h1>
//               <Input
//                 style={stylesInput}
//                 value={state.title}
//                 name={'lastName'}
//                 placeholder={'Last Name'}
//                 onChange={updateField}
//
//               />
//               <Input
//                 style={stylesInput}
//                 name={'firstName'}
//                 onChange={updateField}
//                 placeholder={'First Name'}
//                 rules={[
//                   {
//                     required: true,
//                   },
//                 ]}
//               />
//
//               <Input
//                 style={stylesInput}
//                 name={'suffix'}
//                 onChange={updateField}
//                 placeholder={'Suffix'}
//               />
//               <Input
//                 style={stylesInput}
//                 name={'email'}
//                 onChange={updateField}
//                 placeholder={'email'}
//                 rules={[
//                   {
//                     required: true,
//                   },
//                 ]}
//               />
//
//
//               <Popconfirm placement="top" title={"Free of errors?"}
//                           onConfirm={confirm}
//                           okText="Yes" cancelText="No">
//
//                 <Button
//                   variation="primary"
//                   colorTheme="info"
//                   type="submit"
//                   onClick={event => event.preventDefault()}
//                 >
//                   Create
//                 </Button>
//
//               </Popconfirm>
//             </div>
//           </form>
//         </div>}
//     </>)
// }
//
// export default AddMember;