import React, {useEffect, useState} from 'react';
import axios from "axios";
// import {Link, useNavigate} from 'react-router-dom';

import {ListGroup} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import {useWindowSize} from "../../useWindowSize";
import {Message} from "semantic-ui-react";
import LoaderOverLayAntD from "../LoaderOverLayAntD";
import {Button, message, notification, Popconfirm} from "antd";
import {useSelector} from "react-redux";

import {getDynamicStyleNoPadding} from "../Common/Styles";
import { Radio, Space} from 'antd';


const TriennialRegistration = () => {

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const admin = useSelector(state => state.admin);


//68b4c434-c3b1-400a-9bee-f2f8bba8894f

  useEffect(() => {
    setLoading(true)

    async function fetchData() {
      const triennial = await axios.get('/triennial');
      setState(triennial.data);

      console.log(triennial.data)
    }

    fetchData().then(() => {
      setLoading(false)
    });

  }, [])

  const {width} = useWindowSize();

  const removeMeeting = (id) => {

    axios.post('/triennial/delete', {
      id
    }).then(r => {

      message.success('Triennial successfully deleted ', 2)
        .then(() =>
          message.info("Page will be refreshed", 2))
        .then(() => {
          window.location.reload(false);
        });

    }).catch(e => {
      console.log(e)
    });
  }

  const userState = useSelector(state => state.user);

  const [value, setValue] = useState(0);
  // const [other, setOther] = useState({
  //   lastName: '',
  //   firstName: ''
  // });
  // const navigate = useNavigate();
  // const updateField = e => {
  //   e.persist();
  //   setOther({
  //     ...other,
  //     [e.target.name]: e.target.value
  //   });
  // };
  // const onChange = (e) => {
  //   setValue(e.target.value);
  // };
  const [api, contextHolder] = notification.useNotification();
  // const openNotification = (placement) => {
  //   api.warning({
  //     message: `Warning`,
  //     description:
  //       'A full name is required of the other person',
  //     placement,
  //   });
  // };

  const onChangeOption = (e) => {

    setValue(e.target.value);

    if (e.target.value === 'member') {
      message.info('redirecting to payment link').then(e => {
        axios.get('/triennial-member-payment-link').then(res => {
          window.open(res.data[0].url)//'https://buy.stripe.com/test_14kbK69KZ0P83TO4gj'
        })
      })

    }

    if (e.target.value === 'guest') {
      message.info('redirecting to payment link').then(e => {
        axios.get('/triennial-guest-payment-link').then(res => {
          window.open(res.data[0].url)//'https://buy.stripe.com/test_4gwdSe5uJ7dweys7sw'
        })
      })
    }
  }



  // const handleRegister = (e, m) => {
  //   e.preventDefault()
  //   if (value === 2 && (other.firstName.length < 3 || other.lastName.length < 3)) {
  //     openNotification('bottom');
  //   } else {
  //     navigate('/payment-general', {
  //       state: {
  //         price: m.price,
  //         description: value === 2 ?
  //           m.title + ` registration for ${other.firstName} ${other.lastName}`
  //           : m.title,
  //         otherCosts: m.otherCosts,
  //         otherCostsDescription: m.otherCostsDescription
  //       }
  //     })
  //   }
  // }

  return (<>
      {contextHolder}
      <div style={{
        maxWidth: 1000,
        margin: 'auto',
        top: 70,
        position: "relative"
      }}>

        {loading ? <LoaderOverLayAntD/> : <>

          {state.length === 0 ?
            <Message warning style={
              getDynamicStyleNoPadding(width)
            } header={'No Data'}/> : <>

              {state.map((m, index) =>
                <div key={index}>
                  <Card style={getDynamicStyleNoPadding(width)}>
                    <Card.Body>
                      <Card.Title> {m.title}

                        {Object.values(admin).includes(userState.email) &&
                          <span style={{float: 'right'}}>
                        <Popconfirm
                          title="Delete meeting?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => removeMeeting(m._id)}
                        >
                         <Button type="primary" size={'small'} danger>delete</Button>
                        </Popconfirm>
                      </span>}

                      </Card.Title>
                      <Card.Img variant="top" style={{maxWidth: 590, margin: 'auto'}} src={m.url}/>


                    </Card.Body>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item><b>Where:</b> {m.location}</ListGroup.Item>
                      <ListGroup.Item><b>When:</b> {m.date}</ListGroup.Item>
                      <ListGroup.Item><b>Description:</b> {m.otherComments}</ListGroup.Item>
                      <ListGroup.Item><b>Price: </b>${m.price}</ListGroup.Item>
                      <ListGroup.Item><b>Other Costs: <br/>{m.otherCostsDescription} </b>${m.otherCosts}</ListGroup.Item>

                      {/*<Radio.Group onChange={onChange} style={{marginLeft: 10, marginBottom: 10}} value={value}>*/}
                      {/*  <Space direction="vertical" style={{width: "calc(100% - 2px)"}}>*/}
                      {/*    <Radio value={1}>Register for my self</Radio>*/}
                      {/*    <div*/}
                      {/*      style={value === 2 ? {border: '.5px solid lightGrey', padding: 10, borderRadius: 4} : {}}>*/}
                      {/*      <Radio value={2}>Register other person</Radio>*/}

                      {/*      <div style={width > 450 ? {width: "calc(70% - 2px)"} : {width: "calc(100% - 2px)"}}>*/}
                      {/*        {value === 2 ? (*/}
                      {/*          <>*/}
                      {/*            <p/>*/}
                      {/*            <Input*/}
                      {/*              placeholder="First Name"*/}
                      {/*              value={other.firstName}*/}
                      {/*              name={'firstName'}*/}
                      {/*              onChange={updateField}*/}

                      {/*            />*/}
                      {/*            <p/>*/}
                      {/*            <Input*/}
                      {/*              placeholder="Last Name"*/}
                      {/*              value={other.lastName}*/}
                      {/*              name={'lastName'}*/}
                      {/*              onChange={updateField}*/}

                      {/*            />*/}
                      {/*          </>*/}
                      {/*        ) : null}*/}
                      {/*      </div>*/}

                      {/*    </div>*/}
                      {/*  </Space>*/}

                      {/*</Radio.Group>*/}


                      <Radio.Group onChange={onChangeOption} style={{marginLeft:10,marginTop: 20, marginBottom: 40}} value={value}>
                        <Space direction="vertical">
                          <Radio value={'member'}>
                            <h5>
                              Register my self </h5>
                          </Radio>
                          <Radio value={'guest'}>
                            <h5> Register my guests (ADJUSTABLE QUANTITY up to 4)</h5>
                          </Radio>
                        </Space>
                      </Radio.Group>



                    </ListGroup>


                    {/*{(value === 1 || value === 2) &&*/}
                    {/*  <Card.Body>*/}
                    {/*    <Card.Link*/}
                    {/*      href="#"*/}
                    {/*      as={Link}*/}
                    {/*      to={'/payment-general'}*/}
                    {/*      state={{*/}
                    {/*        price: m.price,*/}
                    {/*        description: value === 2 ?*/}
                    {/*          m.title + ` registration for ${other.firstName} ${other.lastName}`*/}
                    {/*          : m.title,*/}
                    {/*        otherCosts: m.otherCosts*/}
                    {/*      }}*/}
                    {/*      onClick={(e) => handleRegister(e, m)}*/}
                    {/*    >*/}
                    {/*      Register*/}
                    {/*      {*/}
                    {/*        value === 2 &&*/}
                    {/*        <> for {other.firstName} {other.lastName}</>*/}
                    {/*      }*/}
                    {/*    </Card.Link>*/}
                    {/*  </Card.Body>}*/}

                  </Card>
                </div>
              )}
            </>
          }
        </>
        }
      </div>
    </>
  )
}

export default TriennialRegistration;