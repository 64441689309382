import {FETCH_MEMBERS} from "./fetchMembers.types";

const initialState={
  data:[]
}

export const fetchMembersReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_MEMBERS:
      return {
        data:action.payload
      };

    default:
      return state;
  }

}
