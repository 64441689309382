import React, {useEffect, useState} from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileUpload} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {message, Tabs} from "antd";
import LoaderOverLayAntD from "../../../LoaderOverLayAntD";
import { MainGlobal} from "../../../Common/Styles";
import {useWindowSize} from "../../../../useWindowSize";


const DocUpload = () => {
  const [current, setCurrent] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    setCurrent('taxes')
  }, []);


  const handleSubmit = async (files, allFiles) => {
    // return
    const arr = files.map(f => f.file)
    let formData = new FormData();
    for (const key of Object.keys(arr)) {
      formData.append('image', arr[key])
    }

    formData.append('category', current);
    setIsSending(true);

    try {
      await axios.post('/docs', formData);
    } catch (e) {
      message.error('error')
    }
    allFiles.forEach(f => f.remove())
    setIsSending(false);
    message.success(`Successfully Sent:(${displayCategory()})`)
  }

  const displayCategory = () => {
    try {
      return current.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => {
          return str.toUpperCase();
        });
    } catch (e) {
    }
  }

  function handleClick(e) {
    setCurrent(e)
  }

  const items = [
    {
      key: 'taxes',
      label: 'Taxes',

    },
    {
      key: 'statements',
      label: 'Statements',

    },
    {
      key: 'paid Receipts',
      label: 'Paid Receipts',

    },
    {
      key: 'unpaid Receipts',
      label: 'Unpaid Receipts',

    },
    {
      key: 'other',
      label: 'Other',

    },

  ];

  const {width} = useWindowSize();

  return (
    <>
      <div style={
        width > 1000 ?
          {
            width: 1000,
            margin: "auto",
            padding: '0px 10px 0px 10px',

          } :
          {
            width: width - 10,
            margin: "auto",
            padding: '0px 10px 0px 10px',
          }
      }>

        <Tabs defaultActiveKey="1" items={items} mode="horizontal" onChange={handleClick}/>

      </div>

      {isSending && <LoaderOverLayAntD text={isSending ? 'please wait..' : ''}/>}

      <div style={{margin: 20}}>

        <div style={MainGlobal}>
            <span style={{color: 'darkorange', fontSize: 20}}><FontAwesomeIcon icon={faFileUpload}
                                                                               style={{color: 'darkorange'}}/>{' '}
              {displayCategory()}</span>
          <Dropzone
            onSubmit={handleSubmit}
            disabled={isSending}
            accept={'image/*,application/*,text/*'}
            styles={{
              dropzone: {
                width: "auto",
                zIndex: 0,
              }, submitButton: {}, preview: {
                zIndex: 0,
              },
              dropzoneReject: {borderColor: 'red', backgroundColor: '#ddaaaa'},
              inputLabel: (files, extra) =>
                (extra.reject ? {color: 'red'} :
                  {
                    color: '#0c6030',
                    fontSize: 18,
                    border: 'dashed lightGrey 2px',
                    borderRadius: 5,
                    padding: 5
                  }),
            }}
          />
        </div>
      </div>
    </>
  )
}
export default DocUpload;