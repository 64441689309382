import React from "react";
import {useState} from "react";
import {message, Radio, Space} from 'antd';
import axios from "axios";
import {Message} from "semantic-ui-react";
import {useWindowSize} from "../../useWindowSize";
import {useNavigate} from "react-router-dom";
import {LeftOutlined} from "@ant-design/icons";

const PaymentDuesUS = () => {
  const [value, setValue] = useState(1);

  const onChange = (e) => {

    setValue(e.target.value);

    if (e.target.value === 'recurring') {
      message.info('redirecting to recurring payment link').then(e => {
        axios.get('/payment-link-recurring').then(res => {
          window.open(res.data[0].url)//'https://buy.stripe.com/test_14kbK69KZ0P83TO4gj'
        })
      })

    }

    if (e.target.value === 'oneTime') {
      message.info('redirecting to one time payment link').then(e => {
        axios.get('/payment-link-one-time').then(res => {
          window.open(res.data[0].url)//'https://buy.stripe.com/test_4gwdSe5uJ7dweys7sw'
        })
      })
    }
  }
  const {width} = useWindowSize();
  const navigate = useNavigate();

  return (
    <>

      <div style={{maxWidth: 800, margin: "auto"}} className={'main'}>


        <script async
                src={"https://js.stripe.com/v3/buy-button.js"}>
        </script>

        <div style={
          width > 600 ?
            {
              width: 600,
              margin: "auto",

              boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
              borderRadius: 6,
              border: '#f4f4f4 solid .5px'

            } :
            {
              width: width - 10,
              margin: "auto",

              boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
              borderRadius: 6,
              border: '#f4f4f4 solid .5px'
            }
        }>


          <Message header={<h3>Membership Payment US Members</h3>} info
                   style={{margin: 8}}
                   content={<>


                     <Radio.Group onChange={onChange} style={{marginTop: 40, marginBottom: 40}} value={value}>
                       <Space direction="vertical">
                         <Radio value={'recurring'}>
                           <h5>
                             Select this option for annual recurring payment (cancel any time)</h5>
                         </Radio>
                         <Radio value={'oneTime'}>
                           <h5> Select this option for one time payment</h5>
                         </Radio>
                       </Space>
                     </Radio.Group>

                   </>}/>

        </div>

        {/*<div style={{backgroundColor: '#f4f4f4', padding: 20, borderRadius: 5}}>*/}


      </div>

      <div style={{
        maxWidth: 2000,
        marginTop: 50,
        cursor: 'pointer',
        textDecoration: 'underline'
      }}
           onClick={() => navigate('/members', {state: {active: '2'}})}
      >
        <LeftOutlined /> Back

      </div>
    </>
  );
}

export default PaymentDuesUS;