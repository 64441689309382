import {Icon} from "semantic-ui-react";
import {useEffect} from "react";
import {message} from "antd";

const SuccessPayment = () => {

  useEffect(()=>{
    message.success("Thank you for your payment redirecting ...").then(value => {
      window.location.replace('/')
    })
  },[])

  return (
    <div className={"main"}>
      <h1>Payment Succeeded <Icon color={'violet'} name='checkmark'/>
      </h1>
    </div>
  )
}
export default SuccessPayment;