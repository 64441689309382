import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import {useWindowSize} from "../../useWindowSize";
import {Input, message} from "antd";
import TextArea from "antd/es/input/TextArea";
import {Button} from "@aws-amplify/ui-react";
import {Message} from "semantic-ui-react";
import {getDynamicStyle} from "../Common/Styles";

const ContactComponent = () => {
  const form = useRef();
  const {width} = useWindowSize();
  const stylesInput = {
    marginBottom: 10, padding: 5, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({
    subject: '',
    name: '',
    email: '',
    message: '',

  });


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send("service_a4x4vke", "template_l276368", {
      to_name: "Duke Hand Society",
      message: state.message,
      reply_to: state.email,
      subject: state.subject,
      user_email: state.email,
    }, 'wrT1bJkzVq7h2bguS')

      .then((result) => {
        message.success('Your message has been sent').then(() => {
          window.location.replace('/')
        })
      }, (error) => {
        message.error(() => {
          message.error(error)
        })
      }).catch(e => {
      message.error(() => {
        message.error(e)
      })
    });
  };


  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };




  return (
    <div style={
      getDynamicStyle(width)
    }>

      <form style={{marginTop: 20}} ref={form} onSubmit={sendEmail}>

        <div className={'hover-border'}
        >
          <Message info header={<h1>Contact Us</h1>} content={
            'Use the form below to send us a message'
          }/>

          <Input
            style={stylesInput}
            value={state.subject}
            name={'subject'}
            placeholder={'Subject'}
            onChange={updateField}
            required
          />
          <Input
            style={stylesInput}
            value={state.name}
            name={'name'}
            placeholder={'Name'}
            onChange={updateField}
            required
          />

          <Input
            style={stylesInput}
            type="email"
            name="email"
            onChange={updateField}
            placeholder={'Email'}
            required
          />


          <TextArea
            style={stylesInput}
            placeholder={"Message"}
            onChange={updateField}
            name="message"
            size={'large'}
            required
            rows={'6'}
          />

          <Button
            variation="primary"
            colorTheme="info"
            type="submit"
            value="Send"
            onClick={() => sendEmail}
          >
            Send Message
          </Button>

        </div>
      </form>
    </div>
  );
};

export default ContactComponent;