import Navbar from "react-bootstrap/Navbar";
import {Link} from "react-router-dom";
import React from "react";

export default function NavTitle() {
  return <>
    <Navbar.Brand href="#" as={Link}  to="/" >
      <img src="/LogoRound.png" alt="Logo" className={"logo"}/>

    </Navbar.Brand>
    <Navbar.Brand href="#" as={Link} className={"brand"} to="/" >
      <span>Duke Hand Society</span>
    </Navbar.Brand>

  </>;
}