import {DatePicker, Input, message, Popconfirm} from "antd";
import React, {useState} from "react";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import {Button} from "@aws-amplify/ui-react";

import {useSelector} from "react-redux";
import {useWindowSize} from "../../useWindowSize";


const AddASSHMeeting = () => {

  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({
    title: '',
    location: '',
    date: '',
    price: 0,
    otherComments: '',
  });


  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  async function confirm() {

    await axios.post('/meetingASSH',
      {
        data: state
      })
      .then((e) => {
        // console.log(e.data)
      }).then(() => {

        message.success('Event successfully created', 2)
          .then(() =>
            message.info("Page will be refreshed", 2))
          .then(() => {
            window.location.reload();
          }).then(() => {
        });
      })
      .catch(e => {
        console.log(e)
      });
  }

  const dateFormat = 'YYYY/MM/DD';
  const userState = useSelector(state => state.user);
  const {width} = useWindowSize();
  const admin = useSelector(state => state.admin);


  return (
    <>
      {Object.values(admin).includes(userState.email) &&
        <div style={
          width > 600 ?
            {
              width: 600, margin: "auto",
              padding: '0px 10px 0px 10px',
              borderRadius: 6,
            } : {
              width: width - 15, margin: "auto",
              padding: '0px 10px 0px 10px',
              borderRadius: 6,
            }
        }>
          <form style={{marginTop: 20}}>
            <div className={'hover-border'}>
              <h1>ASSH Event</h1>
              <Input
                style={stylesInput}
                value={state.title}
                name={'title'}
                placeholder={'Title'}
                onChange={updateField}

              />
              <Input
                style={stylesInput}
                name={'location'}
                onChange={updateField}
                placeholder={'Location'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />

              <DatePicker
                showTime={false}
                onChange={(e) => setState({
                  ...state,
                  date: e.$d.toDateString()
                })}
                style={stylesInput}
                format={dateFormat}
              />

              <TextArea
                style={stylesInput}
                name={'otherComments'}
                placeholder={"Description"}
                onChange={updateField}
              />
              <Popconfirm placement="top" title={"Free of errors?"}
                          onConfirm={confirm}
                          okText="Yes" cancelText="No">

                <Button
                  variation="primary"
                  colorTheme="info"
                  type="submit"
                  onClick={event => event.preventDefault()}
                >
                  Create
                </Button>

              </Popconfirm>
            </div>
          </form>
        </div>}
    </>)
}

export default AddASSHMeeting;