import {useWindowSize} from "../../useWindowSize";
import {useNavigate} from "react-router-dom";

const PaymentDuesButtonsChoice = () => {
  const {width} = useWindowSize();
  const navigate = useNavigate();
  return (
    <>

      <div className="row" style={
        width > 1000 ?
          {width: 1000, margin: "auto", top: 78, position: "relative"} :
          {width: width - 2, margin: "auto", top: 78, position: "relative"}
      }>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title"> <img src="/icons8-usa-48.png" width={40} alt="usa"/> US Members</h2>
              <h5 className="card-text">
                Use this option if you are a US member
              </h5>
              <p onClick={() => navigate('/payment-dues-us')} className="btn btn-primary">Pay Dues</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title"><img src="/icons8-international-40.png" alt="usa"/> International Members</h2>
              <h5 className="card-text">
                Use this option if you are an international member
              </h5>
              <p onClick={() => navigate('/payment-dues-non-us')}className="btn btn-primary">Pay Dues</p>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default PaymentDuesButtonsChoice;