export const configLogin = {
  "aws_project_region": "us-west-2",
  "aws_cognito_identity_pool_id": "us-west-2:19770687-19ae-4247-b23c-694a838f64c5",
  "aws_cognito_region": "us-west-2",
  "aws_user_pools_id": "us-west-2_gZBeJmoIz",
  "aws_user_pools_web_client_id": "62vk099e48vbtuboocoggekhaf",
  "oauth": {},
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ]
};
