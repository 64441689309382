import {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import {loadStripe} from "@stripe/stripe-js";
import axios from "axios";
import {Button} from "@aws-amplify/ui-react";
import {useLocation} from "react-router-dom";
import {Message} from "semantic-ui-react";
import {useWindowSize} from "../../useWindowSize";

function PaymentGeneral() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const [loading, setLoading] = useState(false);
  const location = useLocation();


  //  use this for autoload
  useEffect(() => {
    (async () => {
      await InitializePaymentIntent((+location.state.price + +location.state.otherCosts), location.state.description)
    })()
  }, [location.state])


  async function InitializePaymentIntent(amount, description) {

    try {
      setLoading(true);

      const r = await axios.get("/stripe/config");
      const {publishableKey} = await r.data;
      setStripePromise(loadStripe(publishableKey));

      const result = await axios.post('/stripe/create-payment-intent', {
        charge: {
          amount,
          currency: 'usd',
          description
        }
      });
      const {client_secret} = await result.data.paymentIntent;
      setClientSecret(client_secret)

    } catch (e) {
      setLoading(false)

    }
    setLoading(false)
  }

  const {width} = useWindowSize();

  return (

    <div style={width > 400 ?
      {width: 400, margin: "auto", marginTop: 75} : {width: width - 10, margin: "auto", marginTop: 75}}>
      <Message info header={`Payment`}
               content={<>Description: {location.state.description}<br/>
                 Amount: Registration(${location.state.price}),
                 Other Costs(${location.state.otherCosts})
                 <p style={{border:'.5px solid #276f86',padding:5,borderRadius:3}}><b >
                   Sum: ${+location.state.price + +location.state.otherCosts}</b> </p></>}/>

      {!clientSecret && !stripePromise &&
        <Button onClick={() => InitializePaymentIntent((+location.state.price + +location.state.otherCosts), location.state.description)}>
          Pay with Card
        </Button>}

      {loading ? <h3>Loading ...</h3> : <>
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{clientSecret}}>
            <CheckoutForm/>
          </Elements>
        )}
      </>}

    </div>

  );
}

export default PaymentGeneral;