import React, {useEffect, useState} from 'react';
import PageTitle from "../Common/PageTitle";
import {SearchOutlined} from '@ant-design/icons';
import {Input, message, Popconfirm, Table} from 'antd';
import axios from "axios";
import LoaderOverLayAntD from "../LoaderOverLayAntD";
import {filterData, resetData} from "../../redux/searchBox/searchBox.actions";
import {useDispatch, useSelector} from "react-redux";
import {Icon, Message} from "semantic-ui-react";

import {useWindowSize} from "../../useWindowSize";

const VisitingProfessors = () => {
  const [allProfessors, setAllProfessors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isStartSearch, setIsStartSearch] = useState(false);

  const userState = useSelector((state => state.user));
  const dispatch = useDispatch();
  const {filteredData} = useSelector(state => state.search);
  const admin = useSelector(state => state.admin);

  useEffect(() => {
    const arr = [];
    dispatch(resetData());
    setIsStartSearch(false);
    setIsLoading(true);

    axios.get('/professor')
      .then(r => {

        Object.entries(r.data).forEach(([key, d]) => {
          arr.push({
            id: d._id,
            key: key,
            name: `${d.name}, ${d.suffix}`,
            year: d.year,
            url: d.url
          })
        });
      }).then(() => {
      arr.sort((a,b) => (b.year - a.year));
      setAllProfessors(arr);
      setIsLoading(false);
    });

  }, [dispatch]);

  const handleChange = (e) => {
    e.persist();
    setIsStartSearch(true);

    const inputValue = e.target.value;
    const filtered = allProfessors.filter(data =>
      Object.keys(data)
        .some(k => data[k].toString().toLowerCase()
          .includes((inputValue).toLowerCase())));
    dispatch(filterData(filtered))
  };


  const confirmDelete = (id) => {

    axios.post(`/professor/delete`, {
      data: {
        id: id
      }
    }).then(r =>
      message.success('Professor successfully Deleted', 2)
        .then(() =>
          message.info("Page will be refreshed", 2))
        .then(() => {
          window.location.reload();
        }).then(() => {
      })
    );

  };


  const columnsAdmin = [
    {
      dataIndex: 'id',
      render: (id) =>
        <Popconfirm
          title="Delete Professor"
          description="Are you sure to delete this Professor"
          onConfirm={() => {
            confirmDelete(id)
          }}
          okText="Yes"
          cancelText="No"
        >
          {Object.values(admin).includes(userState.email) &&
            <div style={{cursor: 'pointer'}}>
              <Icon color={'red'} className={'trash alternate outline'}/>
            </div>
          }

        </Popconfirm>,
      width: '5%'
    },
    {
      dataIndex: 'name',
      width: "30%"

    },
    {
      title: 'Year visited',
      dataIndex: 'year',
      sorter: (a, b) => a.year - b.year,
      width: "30%"
    },

    {
      dataIndex: 'url',
      width: "30%",
      render: (r) => (<><img src={r === '' ? '/avatar.jpg' : r} width={150} alt="professor"/></>)
    }
  ];

  const columns = [
    {
      dataIndex: 'name',
    },
    {
      dataIndex: 'suffix',
    },
    {
      title: 'Year visited',
      dataIndex: 'year',
      sorter: (a, b) => a.year - b.year,
    },
    {
      dataIndex: 'url',
      render: (r) => (<><img src={r === '' ? '/avatar.jpg' : r} width={150} alt="professor"/></>)
    },
  ];
  const {width} = useWindowSize();

  return (
    <>
      <PageTitle title={'Visiting Professors'}/>
      <div className="row" style={
        {marginTop: 120}
      }>
        <>{isLoading ? <LoaderOverLayAntD/> :

          <div style={
            width > 1000 ?
              {
                width: 1000,
                margin: "auto",
                marginTop: 0,
                boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
                padding: '0px 10px 0px 10px',
                borderRadius: 6,
                border: '#f4f4f4 solid .5px'

              } :
              {
                width: width - 10,
                margin: "auto",
                marginTop: 0,
                boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
                padding: '0px 10px 0px 10px',
                borderRadius: 6,
                border: '#f4f4f4 solid .5px'
              }
          }>

            <h1 style={{textAlign: "center", maxWidth: 300, margin: 'auto'}}>
              <Input
                prefix={<SearchOutlined/>}
                style={{marginBottom: 20}}
                placeholder={'Search for a professor'}
                onChange={handleChange}/></h1>


            {(isStartSearch && filteredData.length === 0) ?
              <Message warning>
                <Message.Header>No results</Message.Header>
              </Message> : ''}


            <Table size={'small'} columns={(Object.values(admin).includes(userState.email)) ? columnsAdmin : columns}
                   dataSource={filteredData.length === 0 ? allProfessors : filteredData}/>

          </div>}
        </>

      </div>
    </>
  )
}

export default VisitingProfessors;