import {SIGN_IN_MEMBER, SIGN_UP_MEMBER, SIGN_OUT_MEMBER} from "./member.types";

export const signInMember = (member) => ({
  type: SIGN_IN_MEMBER,
  payload: member
});

export const signUpMember = (member) => ({
  type: SIGN_UP_MEMBER,
  payload: member
});

export const signOutMember = () => ({
  type: SIGN_OUT_MEMBER
});
