import {useWindowSize} from "../../useWindowSize";
import {useNavigate} from "react-router-dom";
import {Icon} from "semantic-ui-react";

const MeetingChoiceButtons = () => {
  const {width} = useWindowSize();
  const navigate = useNavigate();

  return (
    <>
      <div className="row" style={
        width > 1000 ?
          {width: 1000, margin: "auto", top: 78, position: "relative"} :
          {width: width - 2, margin: "auto", top: 78, position: "relative"}
      }>
        <div className="col-sm-6 " onClick={() => navigate('/events')}>
          <div className="card cards-hover">
            <div className="card-body">
              <h2 className="card-title">
                <img src="/Logo_400px.jpg" width={150} alt="assh-logo"/>
                <span style={{marginLeft: 20}}>Events</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="col-sm-6 " style={width <= 575 ? {marginTop: 20} : null}
             onClick={() => navigate('/events-assh')}>
          <div className="card cards-hover">
            <div className="card-body ">
              <h2 className="card-title" style={{}}>
                <img src="/assh.jpg" width={90} alt="assh-logo"/>
                <span style={{marginLeft: 20}}> Annual Meeting</span></h2>
            </div>
          </div>
        </div>
        <div className="col-sm-6 " style={{marginTop: 20}} onClick={() => navigate('/professors')}>
          <div className="card cards-hover">
            <div className="card-body " style={{padding: 77}}>
              <h2 className="card-title" style={{textAlign: "center",}}>
               <Icon name={'address card outline'}/> Visiting professors</h2>
            </div>
          </div>
        </div>
        <div className="col-sm-6 " style={ {marginTop: 20}} onClick={() => navigate('/resident-course')}>
          <div className="card cards-hover">
            <div className="card-body ">
              <h2 className="card-title" style={{padding: 63,textAlign:"center"}}>
                <span style={{marginLeft: 20}}><Icon name={'calendar alternate outline'}/> Annual Course</span></h2>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default MeetingChoiceButtons;