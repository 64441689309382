import React, {useEffect, useState} from 'react';
import {Alert, Input, Table, Tabs,} from "antd";
import './access.css';
import axios from "axios";
import {columns} from "./TableColumns";
import {formatFileSize} from "./docsSearchHelper";
import {useDispatch, useSelector} from "react-redux";
import {SearchOutlined} from "@ant-design/icons";
import {filterData, resetData} from "../../../../redux/searchBox/searchBox.actions";
import LoaderOverLayAntD from "../../../LoaderOverLayAntD";
import {useWindowSize} from "../../../../useWindowSize";


function DocsAccess() {
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState([])
  const [isSearching, setIsSearching] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const {filteredData} = useSelector(state => state.search);
  const dispatch = useDispatch();


  useEffect(() => {
    getDocs('taxes')
  }, [])

  function getDocs(category) {
    const arr = [];
    setLoading(true)
    axios.get('/docs', {
      params: {
        category: category,
        sortType: -1
      }
    }).then(r => {
      Object.entries(r.data).forEach(([key, d]) => {
        arr.push({
          key: key,
          id: d._id,
          size: formatFileSize(d.size, 1),
          public_id: d.public_id,
          originalFileName: d.originalFileName,
          url: d.url,
          created: new Date(d.createdAt.toString()).toUTCString()
        });
      })
    }).then(() => {
      setDocs(arr);
      setLoading(false);
    }).catch(e => {
      console.log(e)
      setLoading(false);
    });
  }


  function handleClick(e) {
    dispatch(resetData());
    setIsSearching(false)
    setInputValue('')
    getDocs(e);

  }


  const handleSearch = (e) => {
    e.persist()
    if (e.target.value.length > 0) {
      setIsSearching(true)
    } else {
      setIsSearching(false)
    }
    const filtered = docs.filter(data =>
      Object.keys(data)
        .some(k => data[k].toString().toLowerCase()
          .includes((e.target.value).toLowerCase())));
    dispatch(filterData(filtered))
  }

  const items = [
    {
      key: 'taxes',
      label: 'Taxes',

    },
    {
      key: 'statements',
      label: 'Statements',

    },
    {
      key: 'paid Receipts',
      label: 'Paid Receipts',

    },
    {
      key: 'unpaid Receipts',
      label: 'Unpaid Receipts',

    },
    {
      key: 'other',
      label: 'Other',

    },

  ];
  const {width} = useWindowSize();
  return (
    <>
      {loading && <LoaderOverLayAntD/>}

      <div style={
        width > 1000 ?
          {
            width: 1000,
            margin: "auto",
            padding: '0px 10px 0px 10px',

          } :
          {
            width: width - 10,
            margin: "auto",
            padding: '0px 10px 0px 10px',
          }
      }>
        <Tabs defaultActiveKey="1" items={items} onChange={handleClick}/>

        <Input
          id={'search'}
          value={inputValue}
          bordered={false}
          size="small"
          placeholder={`file name`}
          prefix={<SearchOutlined/>}
          onChange={(e) => {
            handleSearch(e);
            setInputValue(e.target.value)
          }}
        />
        {(isSearching && filteredData.length === 0) &&
          <Alert message="No file name matches your search" type="warning" showIcon/>}
        <Table scroll={{x: true}} columns={columns} dataSource={
          filteredData.length === 0 ? docs : filteredData
        }/>
      </div>
    </>
  );
}

export default DocsAccess;