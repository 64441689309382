import React from 'react';
import {Route, Routes} from "react-router-dom";
import Home from "../../Pages/Home";
import About from "../../Pages/About";
import MeetingsComponent from "../Meetings/Meetings.component";
import PaymentGeneral from "../Payments/PaymentGeneral";
import LoginForm from "../Auth/LoginForm";
import Members from "../../Pages/Members";
import SuccessPayment from "../Payments/SuccessPayment";
import PaymentDuesUS from "../Payments/PaymentDuesUS";
import AddMeeting from "../Meetings/AddMeeting";
import ResidentCourse from "../Resident/ResidentCourse";
import ContactComponent from "../Contact/ContactComponent";
import AddMember from "../Members/AddMember";
import AdminMain from "../Admin/AdminMain";
import PaymentDuesInter from "../Payments/PaymentDuesInter";
import VisitingProfessors from "../Professors/VisitingProfessors";
import MeetingChoiceButtons from "../Meetings/MeetingsChoiceButtons";
import ASSHMeetingsComponent from "../Meetings/ASSHMeetingsComponent";
import ASSHRegister from "../Meetings/ASSHRegister";
import MemberDirectory from "../Members/MemberDirectory";

import {useSelector} from "react-redux";
import PaymentDuesButtonsChoice from "../Payments/PaymentDuesButtonsChoice";
import TriennialRegistration from "../Members/TriennialRegistration";


export default function RoutesComponent() {
  const user = useSelector(state => state.user);
  const member = useSelector(state => state.member);
  const admin = useSelector(state => state.admin);

  return <>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/events-choice" element={<MeetingChoiceButtons/>}/>
      <Route path="/events" element={<MeetingsComponent/>}/>
      <Route path="/events-assh" element={<ASSHMeetingsComponent/>}/>

      <Route path="/contact" element={<ContactComponent/>}/>
      <Route path="/resident-course" element={<ResidentCourse/>}/>
      <Route path="/payment-general" element={<PaymentGeneral/>}/>
      <Route path="/professors" element={<VisitingProfessors/>}/>

      <Route path="/login" element={<LoginForm/>}/>
      <Route path="/members" element={<Members/>}/>

      <Route path="/register-assh" element={<ASSHRegister/>}/>

      <Route path="/triennial" element={
        (member.isSignedIn || (Object.values(admin).includes(user.email))) ?
          <TriennialRegistration/>: <LoginForm/>
      }/>

      <Route path={'members-directory'} element={
        (member.isSignedIn || (Object.values(admin).includes(user.email))) ?
          <MemberDirectory/> : <LoginForm/>
      }/>
      <Route path="/payment-dues-us" element={
        (member.isSignedIn || (Object.values(admin).includes(user.email))) ?
          <PaymentDuesUS/> : <LoginForm/>}/>

      <Route path="/payment-dues-non-us" element={
        (member.isSignedIn || (Object.values(admin).includes(user.email))) ?
          <PaymentDuesInter/> : <LoginForm/>}/>

      <Route path="/dues-choice" element={
        (member.isSignedIn || (Object.values(admin).includes(user.email))) ?
          <PaymentDuesButtonsChoice/>: <LoginForm/>}/>

      <Route path="/admin-page" element={<AdminMain/>}/>
      <Route path="/add-meeting" element={<AddMeeting/>}/>
      <Route path="/add-member" element={<AddMember/>}/>

      <Route path="/success-payment" element={<SuccessPayment/>}/>
    </Routes>
  </>;
}
