import {message, notification} from "antd";
import React, {useState} from "react";
import axios from "axios";
import {Button} from "@aws-amplify/ui-react";
import {useWindowSize} from "../../useWindowSize";
import {Main} from "./meetingsStyles";
import PageTitle from "../Common/PageTitle";
import {Message} from "semantic-ui-react";
import {getDynamicStyle} from "../Common/Styles";

const ASSHRegister = () => {

  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({
    lastName: '',
    firstName: '',
    suffix: '',
    email: '',
  });

  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  async function confirm(e) {
    e.preventDefault();

    const placement = 'top';
    if (state.email.length < 3 ||
      state.lastName.length < 3 ||
      state.firstName === 0) {
      notification.error({
        message: 'Please fill out all fields',
        placement
      });
      return;
    }

    await axios.post('/assh-register',
      {
        data: state
      })
      .then((e) => {
        // console.log(e.data)
      }).then(() => {

        message.success('Successfully Registered', 2)
          .then(() =>
            message.info("Page will be refreshed", 2))
          .then(() => {
            window.location.reload();
          }).then(() => {
        });

      })
      .catch(e => {
        console.log(e)
      });
  }


  const {width} = useWindowSize();


  return (
    <>
      <PageTitle title={'ASSH Register'}/>
      <div style={Main}>

        <div style={
          getDynamicStyle(width)
        }>
          <form style={{marginTop: 20}}>
            <div>
              <Message info header={<h4>Use the form below to register</h4>}/>
              <input
                style={stylesInput}
                value={state.title}
                name={'lastName'}
                placeholder={'Last Name'}
                onChange={updateField}
                required={true}
              />
              <input
                style={stylesInput}
                name={'firstName'}
                onChange={updateField}
                placeholder={'First Name'}
                required={true}
              />

              <input
                style={stylesInput}
                name={'suffix'}
                onChange={updateField}
                placeholder={'Suffix'}
              />
              <input
                style={stylesInput}
                name={'email'}
                onChange={updateField}
                placeholder={'email'}
                required={true}

              />
              <Button
                variation="primary"
                colorTheme="info"
                type="submit"
                onClick={confirm}
              >
                Submit
              </Button>

            </div>
          </form>
        </div>
      </div>
    </>)
}

export default ASSHRegister;