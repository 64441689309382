import {SearchOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal, Popconfirm, Table, Tag} from 'antd';
import axios from "axios";
import LoaderOverLayAntD from "../LoaderOverLayAntD";
import {filterData, resetData} from "../../redux/searchBox/searchBox.actions";
import {useDispatch, useSelector} from "react-redux";
import {Icon, Message, Image} from "semantic-ui-react";

import {useWindowSize} from "../../useWindowSize";

const MemberDirectory = () => {

    const [allMembers, setAllMembers] = useState([]);
    const [stateEdit, setStateEdit] = useState({});
    const [editMember, setEditMember] = useState({});

    const admin = useSelector(state => state.admin);

    const [isLoading, setIsLoading] = useState(true);
    const [isStartSearch, setIsStartSearch] = useState(false);

    const userState = useSelector((state => state.user));
    const dispatch = useDispatch();
    const {filteredData} = useSelector(state => state.search);

    useEffect(() => {
      const arr = [];
      setPreviewUrl(null);
      dispatch(resetData());
      setIsStartSearch(false);
      setIsLoading(true);

      axios.get('/member')
        .then(r => {

          Object.entries(r.data).forEach(([key, d]) => {
            arr.push({
              id: d._id,
              key: key,
              fullName: d.lastName + ' ' + d.firstName + ' ' + d.suffix,
              lastName: d.lastName,
              firstName: d.firstName,
              suffix: d.suffix,
              memberImage: d.memberImage,
            });
          });
        }).then(() => {
        setAllMembers(arr);
        setIsLoading(false);
      });

    }, [dispatch]);

    const handleChange = (e) => {
      e.persist();
      setIsStartSearch(true);

      const inputValue = e.target.value;
      const filtered = allMembers.filter(data =>
        Object.keys(data)
          .some(k => data[k].toString().toLowerCase()
            .includes((inputValue).toLowerCase())));
      dispatch(filterData(filtered))
    };

    const confirmDelete = (id) => {

      axios.post(`/member/delete`, {
        data: {
          id: id
        }
      }).then(r =>
        message.success('Member successfully Deleted', 2)
          .then(() =>
            message.info("Page will be refreshed", 1))
          .then(() => {
            window.location.reload();
          }).then(() => {
        })
      );

    };

    const [modalState, setModalState] = useState({
      open: false,
      userId: '',
      isLoading: false
    });
    const showModal = (id) => {

      setModalState({
        ...modalState,
        open: true,
        userId: id,
        isLoading: true
      });


      axios.get('/member/:id', {
        params: {
          id: id
        }
      }).then(r => {

        setStateEdit({
          ...stateEdit,
          id: r.data._id,
          lastName: r.data.lastName,
          firstName: r.data.firstName,
          suffix: r.data.suffix,
          email: r.data.email,
          memberImage: r.data.memberImage,

          officeAddressStreet: r.data.officeAddressStreet,
          officeAddressCity: r.data.officeAddressCity,
          officeAddressSuite: r.data.officeAddressSuite,
          officeAddressState: r.data.officeAddressState,

          officeTelephone: r.data.officeTelephone,
          officeWebLink: r.data.officeWebLink,
          mobilePhone: r.data.mobilePhone,
          specialty: r.data.specialty,
          practiceType: r.data.practiceType,
          joinDate: r.data.joinDate,
          memberType: r.data.memberType,
          spouseName: r.data.spouseName
        });


      }).then(() => {
        setModalState({
          ...modalState,
          open: true,
          userId: id,
          isLoading: false
        });
      }).catch(e => {
        message.error('Something went wrong ' + e)
      })

    }

    const updateField = e => {
      e.persist();
      setStateEdit({
        ...stateEdit,
        [e.target.name]: e.target.value
      });
    };

    const handleOk = () => {
      setModalState({
        ...modalState,
        open: false
      });
      setEditMember({
        isStartEditing: false
      });
      setPreviewUrl(null);

    };

    const columnsAdmin = [
      {
        dataIndex: 'id',
        render: (id) =>
          <Popconfirm
            title="Delete Member"
            description="Are you sure to delete this member"
            onConfirm={() => {
              confirmDelete(id)
            }}
            okText="Yes"
            cancelText="No"
          >
            {Object.values(admin).includes(userState.email) &&
              <div style={{cursor: 'pointer'}}><Icon color={'red'} className={'trash alternate outline'}/></div>
            }
          </Popconfirm>,

        width: '5%'
      },
      {
        dataIndex: 'memberImage',
        render: (memberImage) =>
          <img src={memberImage === '' ? "/avatar.png" : memberImage} width={50} alt="avatar"/>,
        width: '10%'
      },
      {
        title: 'Name ',
        dataIndex: 'fullName',
        width: "50%"
      },
      {
        dataIndex: 'id',
        render: (id) =>
          <Tag
            style={{cursor: "pointer"}}
            color={'geekblue'}
            onClick={() => showModal(id)}
          >More Info</Tag>,
        width: "50%"
      },

    ];

    const columns = [
      {
        dataIndex: 'memberImage',
        render: (memberImage) =>
          <img src={memberImage === '' ? "/avatar.jpg" : memberImage} width={50} alt="avatar"/>,
        width: '10%'
      },
      {
        title: 'Name ',
        dataIndex: 'fullName',
        width: "50%"
      },
      {
        dataIndex: 'id',
        render: (id) =>
          <Tag
            style={{cursor: "pointer"}}
            color={'geekblue'}
            onClick={() => showModal(id)}
          >More Info</Tag>,
        width: "50%"
      },
    ];

    const {width} = useWindowSize();

    function isOwnerOrAdmin() {
      return stateEdit.email === userState.email || Object.values(admin).includes(userState.email);
    }

    const [img, setImg] = useState("");
    const [previewUrl, setPreviewUrl] = useState(null)

    const updateImage = (e) => {
      setImg(e.target.files[0]);
      setPreviewUrl(URL.createObjectURL(e.target.files[0]))
    }


    const saveUpdatedData = (e) => {
      e.preventDefault();


      const data = new FormData();
      data.append("file", img);
      data.append("upload_preset", 'rridxch3');
      data.append("cloud_name", 'duke-hand-society');
      // data.append("folder","your-folder-name");

      const previousImagePublicId = stateEdit.memberImage.split("/")
        .pop().replace(".jpg", "");

      if (img === '') {
        axios.put('/member',
          {
            id: stateEdit.id,
            memberImage: stateEdit.memberImage,
            officeAddressStreet: stateEdit.officeAddressStreet,
            officeAddressCity: stateEdit.officeAddressCity,
            officeAddressSuite: stateEdit.officeAddressSuite,
            officeAddressState: stateEdit.officeAddressState,
            officeTelephone: stateEdit.officeTelephone,
            officeWebLink: stateEdit.officeWebLink,
            lastName: stateEdit.lastName,
            firstName: stateEdit.firstName,
            suffix: stateEdit.suffix,
            email: stateEdit.email,
            mobilePhone: stateEdit.mobilePhone,
            specialty: stateEdit.specialty,
            practiceType: stateEdit.practiceType,
            joinDate: stateEdit.joinDate,
            memberType: stateEdit.memberType,
            spouseName: stateEdit.spouseName,
            previousImagePublicId: previousImagePublicId
          })

          .then((e) => {

            setEditMember({
              isStartEditing: false
            })
          }).then(() => {

          message.success('Member successfully updated', 2)
            .then(() =>
              message.info("Page will be refreshed", 2))
            .then(() => {
              window.location.reload();
            }).then(() => {

          });

        })
          .catch(e => {
            console.log(e)
          });
        return;
      }


      axios.post(`https://api.cloudinary.com/v1_1/duke-hand-society/image/upload`, data)
        .then(r => {

          axios.put('/member',
            {
              id: stateEdit.id,
              memberImage: r.data.secure_url,
              officeAddressStreet: stateEdit.officeAddressStreet,
              officeAddressCity: stateEdit.officeAddressCity,
              officeAddressSuite: stateEdit.officeAddressSuite,
              officeAddressState: stateEdit.officeAddressState,
              officeTelephone: stateEdit.officeTelephone,
              officeWebLink: stateEdit.officeWebLink,
              lastName: stateEdit.lastName,
              firstName: stateEdit.firstName,
              suffix: stateEdit.suffix,
              email: stateEdit.email,
              mobilePhone: stateEdit.mobilePhone,
              specialty: stateEdit.specialty,
              practiceType: stateEdit.practiceType,
              joinDate: stateEdit.joinDate,
              memberType: stateEdit.memberType,
              spouseName: stateEdit.spouseName,
              previousImagePublicId: previousImagePublicId
            })

            .then((e) => {

              setEditMember({
                isStartEditing: false
              })
            }).then(() => {

            message.success('Member successfully updated', 2)
              .then(() =>
                message.info("Page will be refreshed", 2))
              .then(() => {
                window.location.reload();
              }).then(() => {
            });

          })
            .catch(e => {
              message.error('Member could not be updated').then(e => {
                console.log(e)
              });

            });

        }).catch(err => {
        console.log(err)
      });


    }

    return (<>{isLoading && <LoaderOverLayAntD/>}

      <div style={
        width > 1000 ?
          {
            width: 1000,
            margin: "auto",
            marginTop: 78,
            boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
            padding: '0px 10px 0px 10px',
            borderRadius: 6,
            border: '#f4f4f4 solid .5px'

          } :
          {
            width: width - 10,
            margin: "auto",
            marginTop: 78,
            boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
            padding: '0px 10px 0px 10px',
            borderRadius: 6,
            border: '#f4f4f4 solid .5px'
          }
      }>

        <Modal
          afterClose={() => {
            setPreviewUrl(null)
          }}
          width={1000}
          title={<>
            <div className={'row bg-body-tertiary p-3 mb-4'}>
              <div>
                <h3>
                  <b>{stateEdit.lastName +
                    ' ' + stateEdit.suffix + ' '
                    + stateEdit.firstName}</b>
                </h3>
              </div>

              {isOwnerOrAdmin() &&
                <div className={''}>
                  <Button
                    size={'small'}
                    className={'bg-warning text-light'} onClick={() => {
                    setEditMember({
                      isStartEditing: true
                    })
                  }}>
                    <Icon name={'edit'}/> Edit</Button>

                  {editMember.isStartEditing &&
                    <Button
                      size={'small'}
                      className={'bg-secondary text-light'}
                      onClick={() => {
                        setEditMember({
                          isStartEditing: false
                        });
                        setPreviewUrl(null);
                        handleOk();
                      }}><Icon name={'cancel'}/> Cancel</Button>}

                  {editMember.isStartEditing &&
                    <Button
                      size={'small'}
                      className={'bg-success text-light'}
                      onClick={saveUpdatedData}><Icon name={'check square outline'}/> Save</Button>}


                </div>}


            </div>
          </>}
          open={modalState.open}
          onCancel={handleOk}
          closable={false}
          footer={<Button type={'default'} onClick={handleOk}>Close</Button>}
        >

          <div className="row pb-4">
            <div className="col-lg-3">
              <div>
                {editMember.isStartEditing &&
                  <input
                    type="file"
                    onChange={updateImage}
                    className="form-control shadow-sm"
                    id="image"
                    name="image"
                    accept="image/*"
                  />}

                <Image style={{objectFit: 'cover'}}
                       src={previewUrl ? previewUrl : (stateEdit.memberImage === '' ?
                         '/avatar.jpg' : stateEdit.memberImage)}/>
              </div>
            </div>

            <div className="col-lg-8">
              <div>
                <h5 className="card-title"><b><Icon name={'user'} color={'yellow'}/> Member Profile</b></h5>
                <div className="card-text">

                  <b>Member Type:</b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.memberType}
                      name={'memberType'}
                      onChange={updateField}
                    /> :
                    <>{stateEdit.memberType}</>}
                </div>

                <div className="card-text">
                  <b>Practice Type: </b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.practiceType}
                      name={'practiceType'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.practiceType}</>}
                </div>

                <div className="card-text">
                  <b>Join Date:</b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.joinDate}
                      name={'joinDate'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.joinDate}</>}
                </div>

                <div className="card-text">
                  <b>email:</b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.email}
                      name={'email'}
                      onChange={updateField}
                    /> :
                    <>
                      {<a href={`mailto:${stateEdit.email}`}> {stateEdit.email}</a>}</>}
                </div>


                <div className="card-text"><b>Mobile Phone: </b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.mobilePhone}
                      name={'mobilePhone'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.mobilePhone}</>}
                </div>


                <div className="card-text"><b>Spouse Name: </b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.spouseName}
                      name={'spouseName'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.spouseName}</>}
                </div>


              </div>
            </div>
          </div>

          <div>
            <div className="col-lg-12  bg-body-tertiary  p-2 text-bg-info">
              <div className="">
                <h5 className="card-title"><b><Icon name={'user md'} color={'yellow'}/> Office Address</b></h5>
                <div className="card-text">
                  <b>Street: </b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.officeAddressStreet}
                      name={'officeAddressStreet'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.officeAddressStreet}</>}
                </div>


                <div className="card-text">
                  <b>Suite: </b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.officeAddressSuite}
                      name={'officeAddressSuite'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.officeAddressSuite}</>}
                </div>


                <div className="card-text">
                  <b>City: </b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.officeAddressCity}
                      name={'officeAddressCity'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.officeAddressCity}</>}
                </div>

                <div className="card-text">
                  <b>State: </b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.officeAddressState}
                      name={'officeAddressState'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.officeAddressState}</>}
                </div>


                <div className="card-text">
                  <b>Phone: </b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.officeTelephone}
                      name={'officeTelephone'}
                      onChange={updateField}
                    /> :
                    <>
                      {stateEdit.officeTelephone}</>}
                </div>


                <div className="card-text">
                  <b>Web:</b>
                  {editMember.isStartEditing ?
                    <input
                      style={{width: 300}}
                      type="text"
                      value={stateEdit.officeWebLink}
                      name={'officeWebLink'}
                      onChange={updateField}
                    /> :
                    <>
                      <a href={stateEdit.officeWebLink}>{stateEdit.officeWebLink}</a></>}
                </div>
              </div>
            </div>

          </div>

        </Modal>

        <h1 style={{textAlign: "center", maxWidth: 300, margin: 'auto'}}>
          <Input
            prefix={<SearchOutlined/>}
            style={{marginBottom: 20}}
            placeholder={'Search for a member'}
            onChange={handleChange}/></h1>


        {
          (isStartSearch && filteredData.length === 0) ?
            <Message warning>
              <Message.Header>No results</Message.Header>
            </Message> : ''
        }


        <Table size={'small'} columns={(Object.values(admin).includes(userState.email)) ? columnsAdmin : columns}
               dataSource={filteredData.length === 0 ? allMembers : filteredData}/>
      </div>

    </>)
  }
;
export default MemberDirectory;