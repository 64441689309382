import React, {useState} from "react";
import axios from 'axios';
import {Input, message, Popconfirm} from "antd";
import {useWindowSize} from "../../useWindowSize";
import {useSelector} from "react-redux";
import {Button} from "@aws-amplify/ui-react";


const AddProfessor = () => {

  const [img, setImg] = useState("");
  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({
    name: '',
    suffix: '',
    year: '',
    url: ''
  });


  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };
  const updateImage = (e) => {
    setImg(e.target.files[0]);
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", img);
    data.append("upload_preset", 'rridxch3');
    data.append("cloud_name", 'duke-hand-society');
    // data.append("folder","your-folder-name");

    // pastProfessors.forEach(p => {
    //   axios.post('/professor',
    //     {
    //       data: {
    //         name: p.Name, suffix: p.Suffix, year: p.Year, url: ''
    //       }
    //     })
    //     .then((e) => {
    //       // console.log(e.data)
    //     }).then(() => {
    //
    //     message.success('Professor successfully created', 2)
    //       .then(() =>
    //         message.info("Page will be refreshed", 2))
    //       .then(() => {
    //         window.location.reload();
    //       }).then(() => {
    //     });
    //
    //   })
    //     .catch(e => {
    //       console.log(e)
    //     });
    // })


    axios.post(`https://api.cloudinary.com/v1_1/duke-hand-society/image/upload`, data).then(r => {

      axios.post('/professor',
        {
          data: {
            name: state.name,
            suffix: state.suffix,
            year:state.year,
            url: r.data.secure_url
          }
        })
        .then((e) => {
          // console.log(e.data)
        }).then(() => {

        message.success('Professor successfully created', 2)
          .then(() =>
            message.info("Page will be refreshed", 2))
          .then(() => {
            window.location.reload();
          }).then(() => {
        });

      })
        .catch(e => {
          console.log(e)
        });

    }).catch(err => {
      console.log(err)
    })
  }
  const {width} = useWindowSize();

  const userState = useSelector(state => state.user);
  const admin = useSelector(state => state.admin);

  return (
    <>
      {Object.values(admin).includes(userState.email) &&

        <div style={
          width > 600 ?
            {width: 600, margin: "auto"} : {width: width - 15, margin: "auto"}
        }>
          <form style={{marginTop: 20}}>
            <div>
              <h1>New Professor</h1>

              <Input
                style={stylesInput}
                name={'name'}
                onChange={updateField}
                placeholder={'Full Name'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />

              <Input
                style={stylesInput}
                name={'suffix'}
                onChange={updateField}
                placeholder={'Suffix'}
              />
              <Input
                style={stylesInput}
                name={'year'}
                onChange={updateField}
                placeholder={'Year of visiting'}
              />

              <h5 style={{color: "#b4b4b4"}}>Professor Image (drag image or click to choose) </h5>
              <input
                style={stylesInput}
                type="file"
                onChange={updateImage}
                className="form-control shadow-sm"
                id="image"
                name="image"
                accept="image/*"
              />

              <Popconfirm placement="top" title={"Free of errors?"}
                          onConfirm={handleUpload}
                          okText="Yes" cancelText="No">

                <Button
                  variation="primary"
                  colorTheme="info"
                  type="submit"
                  onClick={event => event.preventDefault()}
                >
                  Create
                </Button>

              </Popconfirm>
            </div>
          </form>
        </div>
      }
    </>
  );
}

export default AddProfessor;