import {SIGN_IN_MEMBER, SIGN_OUT_MEMBER, SIGN_UP_MEMBER} from "./member.types";


const initialState = {
  memberId: null,
  memberEmail: null,
  isSignedIn: false,
};

const memberReducer = (state = initialState, action) => {

  switch (action.type) {

    case SIGN_IN_MEMBER:
      return {
        ...action.payload,
        isSignedIn: true
      };
    case SIGN_UP_MEMBER:
      return {
        ...state,
        memberEmail: action.payload.email,
      };

    case SIGN_OUT_MEMBER:
      return initialState;

    default:
      return state;
  }
};

export default memberReducer;