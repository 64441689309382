import {AddressElement, PaymentElement} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import {useStripe, useElements} from "@stripe/react-stripe-js";
import '../../stripeStyles.css'
import {Placeholder} from "semantic-ui-react";
import {useWindowSize} from "../../useWindowSize";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFakeLoading, setIsFakeLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target.value)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const r = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/success-payment`,
      },
    });

    if (r.error.type === "card_error" || r.error.type === "validation_error") {
      setMessage(r.error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsProcessing(false);
  };

  useEffect(() => {
    const timeout = setInterval(() => {
      setIsFakeLoading(true)
    }, 1500)

    return () => clearTimeout(timeout)
  }, [])


  const {width} = useWindowSize();

  return (
    <>
      {!isFakeLoading && <Placeholder className={"main"} style={
        width > 400 ?
          {width: 400, height: 500} : {width: width - 5, height: 400}
      }
      >
        <Placeholder.Image/>
      </Placeholder>
      }
      <>

        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element"/>
          <AddressElement options={{mode: 'billing'}}/>
          <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </>
    </>
  );
}

