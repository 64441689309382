import {GET, REMOVE} from "./admin.types";

const initialState = {data:{}};

const adminReducer = (state = initialState, action) => {

  switch (action.type) {

    case GET:
      return {
        ...action.payload
      };
    case REMOVE:
      return {
        initialState
      };

    default:
      return state;
  }
};

export default adminReducer;