import React, {useState} from 'react';
import {Menu} from 'antd';
import AddMember from "../Members/AddMember";
import AddMeeting from "../Meetings/AddMeeting";
import {useSelector} from "react-redux";
import DocsAccess from "./DocsAdmin/DocsSearch/DocsAccess";
import DocUpload from "./DocsAdmin/DocsUpload/DocUpload";
import {useWindowSize} from "../../useWindowSize";
import RecurringPaymentLinkUs from "./PaymentLink/RecurringPaymentLinkUs";
import OneTimePaymentLinkUs from "./PaymentLink/OneTimePaymentLinkUs";
import OneTimePaymentLinkNonUs from "./PaymentLink/OneTimePaymentLinkNonUs";
import RecurringPaymentLinkNonUs from "./PaymentLink/RecurringPaymentLinkNonUs";
import AddProfessor from "../Professors/AddProfessor";
import AddTriennial from "../Meetings/AddTriennial";
import ResidentPaymentLink from "./PaymentLink/ResidentPaymentLink";
import AddASSHMeeting from "../Meetings/AddASSHMeeting";
import {Message} from "semantic-ui-react";
import ASSHRegisterUsers from "../Meetings/ASSHRegisterUsers";
import AddAdmin from "./AdminsHandle/AddAdmin";
import ListAdmins from "./AdminsHandle/ListAdmins";
import TriennialPaymentLinkMember from "./PaymentLink/TriennialPaymentLinkMember";
import TriennialPaymentLinkGuest from "./PaymentLink/TriennialPaymentLinkGuest";


const AdminMain = () => {
  const items = [
    {
      key: '1',
      label: 'Member',
    },
    {
      key: '2',
      label: 'Professor',
    },
    {
      label: 'Events',
      children: [
        {
          label: 'New Event',
          key: '3',
        },
        {
          label: 'ASSH Event',
          children: [
            {
              label: 'New ASSH Event',
              key: '12',
            },
            {
              key: '13',
              label: 'ASSH registered users',
            },
          ],
        },
        {
          key: '4',
          label: 'Triennial',
        },
      ],
    },

    {
      label: 'Docs',
      children: [
        {
          type: 'group',
          children: [
            {
              key: '5',
              label: 'Upload Docs',
            },
            {
              key: '6',
              label: 'Access Docs',
            },
          ],
        }
      ],
    },
    {
      label: "Payments",
      children: [
        {
          type: 'group',
          children: [
            {
              key: 'US-Member Payments links',
              label: 'US-Member Payments links',
              children: [
                {
                  type: 'group',
                  children: [
                    {
                      label: 'Recurring',
                      key: '7',
                    },
                    {
                      label: 'One Time',
                      key: '8',
                    },
                  ],
                }
              ],
            },
            {
              key: 'NON-US-Member Payments links',
              label: 'NON-US-Member Payments links',
              children: [
                {
                  type: 'group',
                  children: [
                    {
                      label: 'Recurring',
                      key: '9',
                    },
                    {
                      label: 'One Time',
                      key: '10',
                    },
                  ],
                }
              ],
            },
            {
              key: '11',
              label: 'Resident Course',
            }, {
              key: '16',
              label: 'PaymentLink Triennial Member',
            }, {
              key: '17',
              label: 'PaymentLink Triennial Guest',
            }
          ],
        }
      ],
    },
    {
      label: "Admins",
      children: [
        {
          type: 'group',
          children: [
            {
              label: 'Add',
              key: '14',
            },
            {
              label: 'List',
              key: '15',
            },

          ],
        }
      ],
    },
  ];

  const userState = useSelector((state => state.user));
  const [current, setCurrent] = useState('0');
  const admin = useSelector(state => state.admin);


  const onClick = (e) => {
    setCurrent(e.key);
  }
  const {width} = useWindowSize();

  const menuElements = [
    <Message style={{marginTop: 15, maxWidth: 400}} success header={
      <><h1>Admin Page</h1></>} content={<span>Admin: {userState.email}</span>}/>,
    <AddMember/>,
    <AddProfessor/>,
    <AddMeeting/>,
    <AddTriennial/>,
    <DocUpload/>,
    <DocsAccess/>,
    <RecurringPaymentLinkUs/>,
    <OneTimePaymentLinkUs/>,
    <RecurringPaymentLinkNonUs/>,
    <OneTimePaymentLinkNonUs/>,
    <ResidentPaymentLink/>,
    <AddASSHMeeting/>,
    <ASSHRegisterUsers/>,
    <AddAdmin/>,
    <ListAdmins/>,
    <TriennialPaymentLinkMember/>,
    <TriennialPaymentLinkGuest/>
  ]


  return (
    <>
      {Object.values(admin).includes(userState.email) &&

        <div style={
          width > 1000 ?
            {width: 1000, margin: "auto", marginTop: 60} : {width: width - 10, margin: "auto", marginTop: 70}
        }>

          <Menu style={{background: '#f5f5f5'}} onClick={onClick} selectedKeys={[current]} mode="horizontal"
                items={items}/>

          {menuElements.map((element, index) => {
            if (current === index.toString()) {
              return <div key={index}>{element}</div>
            }
            return null
          })}

        </div>}

    </>
  )
}

export default AdminMain;