export const Label = {
  textAlign: 'center',
  color: '#0c3c60',
  fontSize: 40,
  fontFamily: 'raleway, san serif',
  fontWeight: 600
}
export const LabelLeft = {
  textAlign: 'center',
  color: '#0c3c60',
  fontSize: 40,
  fontFamily: 'raleway, san serif',
  fontWeight: 600,
  float:'left'
}

export const Main = {
  maxWidth: 1000,
  margin: 'auto',
  marginTop: 120,
}
export const VerticalLine = {
  display: 'grid',
  border: "solid",
  borderBottom: "none",
  borderRight: "none",
  borderTop: "none",
  borderLeftWidth: 6,
  borderLeftColor: "#1c602f",
  paddingLeft: 10,
}
export const RegisterButton = {
  padding: 4,
  background: "none",
  border: 'solid #0c6030',
  borderRadius: 3,
  marginTop: 24,
  color: '#0c3c60',
  width: 130,
  margin: 'auto'
}