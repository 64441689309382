import NavDropdown from "react-bootstrap/NavDropdown";
import {Link} from "react-router-dom";
import AdminLInk from "./AdminLInk";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {Icon} from "semantic-ui-react";
import {useWindowSize} from "../../useWindowSize";
import {signOut} from 'aws-amplify/auth';
import {signOutUser} from "../../redux/user/user.actions";
import {signOutMember} from "../../redux/member/member.actions";
import {message} from "antd";
import { LogoutOutlined, ScheduleOutlined} from "@ant-design/icons";


const MoreMenuDropDown = () => {

  const userState = useSelector((state => state.user));
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const admin = useSelector(state => state.admin);

  async function handleSignOut(e) {
    e.preventDefault()
    try {
      await signOut().then(() => {
        message.info('You are signed out ' + userState.email);
        dispatch(signOutUser())
        dispatch(signOutMember())
      });

    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <>
      <NavDropdown
        href="#6" drop={'start'}
        title={<><Icon name={'dropdown'}/>
          {windowSize.width <= 575 ? <span>More </span> : ''}</>}
        id="collapsible-nav-dropdown">

        <NavDropdown.Item href="#3" as={Link} to="/events-choice">
          <div><ScheduleOutlined/> Events</div>
        </NavDropdown.Item>

        {/*<NavDropdown.Item href="#41" as={Link} to="/professors">*/}
        {/*  <div><Icon name={'address card outline'}/>Professors</div>*/}
        {/*</NavDropdown.Item>*/}

        {/*<NavDropdown.Item href="#42" as={Link} to="/resident-course">*/}
        {/*  <div><TeamOutlined/> Residents</div>*/}
        {/*</NavDropdown.Item>*/}

        <NavDropdown.Item href="#4" as={Link} to="/members" style={{color: "darkorange"}}>
          <div><Icon name={'list ul'}/> Members</div>
        </NavDropdown.Item>

        <NavDropdown.Item
          href="#5"
          as={Link}
          onClick={userState.isSignedIn ? handleSignOut : null}
          to="/login">
          {userState.isSignedIn ?
            <div style={{backgroundColor: 'orange', color: "white", padding: 10}}><LogoutOutlined/> Logout
            </div> : <></>}
        </NavDropdown.Item>
        {/*if admin show admin menu*/}

        {Object.values(admin).includes(userState.email) && <> <NavDropdown.Divider/>
          <AdminLInk/>
        </>}
        {/*<span style={{textAlign:"center",marginTop: 8,margin:'auto',color:'#1f7294',*/}
        {/* /* border:'.5px solid #a9d5de', borderRadius:5,backgroundColor:'#f4ffff',*!/}>*/}
        {/*  {<>Logged in as {userState.email}</>}</span>*/}
      </NavDropdown>


    </>
  )
}
export default MoreMenuDropDown;