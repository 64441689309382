import React from "react";
import {useWindowSize} from "../useWindowSize";

const About = () => {
  const {width} = useWindowSize();

  return (
    <>
      <h1 className={'page-title-about'}
      style={
        width > 600 ?
        {fontSize: 28, textAlign:"center"} : {fontSize: 20, textAlign:"center"}
      }
      >The History of the Duke Hand Society</h1>



      <div className={'main'} style={{fontSize:15, maxWidth:1000}}>
        <p>
          The Duke Hand Society has its roots in the Duke Hand Club, which had its first meeting at the American Society
          for Surgery of the Hand meeting in Atlanta, GA in 1984. The Duke Hand Club had 42 founding members, most of
          whom
          were former fellows from the Duke hand program, which had been training hand surgeons since 1956. Dr. Lamar
          Fleming of Emory University’s Department of Orthopedics in Atlanta was named the Club’s first president.
        </p>
        <p>
          The first official meeting occurred in 1986 in Durham, NC. The first guest lecturer was Dr. Shaw Wilgis from
          Union Memorial Hospital/Curtis National Hand Center in Baltimore. Presentations were also made by Dr. Leonard
          Goldner, Dr. Jim Urbaniak and Dr. James Nunley, all renowned surgeons at Duke.
        </p>
        <p>
          Dr. John Gould held the next meeting away from Duke in Steamboat Springs, Colorado. The organization has
          continued to meet every three years, normally in conjunction with the triennial meeting of the International
          Federation of Societies for Surgery of the Hand. The Duke Hand Society recently held its 14th triennial
          meeting.

        </p>
        <p>
          The Duke Hand Society is a nonprofit, tax-exempt organization under IRS Code Section 501(c)(3) as a charitable
          organization. The organization has a three-part mission of education, service and outreach. For more than the
          past decade, the Society has hosted an annual upper extremity educational review course for residents and
          fellows. The Society has helped fund international medical missions for several years in Honduras and in 2024
          is
          embarking on an ongoing mission program in Ghana. In addition, locally, the Society annually hosts a luminary
          surgeon in the field of hand surgery for the purpose of educating Duke faculty, residents and fellows.
        </p>

      </div>
    </>
  )
}
export default About