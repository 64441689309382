import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import React from "react";
import {Link} from "react-router-dom";
import NavTitle from "./NavTitle";
import MoreMenuDropDown from "./MoreMenuDropDown";
import {InfoCircleOutlined, MailOutlined} from "@ant-design/icons";
import {useWindowSize} from "../../useWindowSize";

export default function HorizontalLinks(props) {
  const windowSize = useWindowSize();

  return <>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${props.expand}`}>
        <NavTitle/>
      </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <Nav className="justify-content-end flex-grow-1 pe-3 nav-underline">
        {/*<Nav.Link color={defaultColor.backgroundColor} href="#home" as={Link} to='/'>HOME</Nav.Link>*/}
        <Nav.Link style={windowSize.width >= 575 ? {color: "white"} : {color: "black"}} href="#about" as={Link}
                  to="/about">
          <InfoCircleOutlined/> About</Nav.Link>
        <Nav.Link style={windowSize.width >= 575 ? {color: "white"} : {color: "black"}} href="#contact" as={Link} to='/contact'>
          <MailOutlined/> Contact</Nav.Link>
        <MoreMenuDropDown/>
      </Nav>
    </Offcanvas.Body>
  </>;
}