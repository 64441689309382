import React, {useEffect} from "react";
import MainMenu from "./Components/MenuComponent/MainMenu";
import {getCurrentUser} from "aws-amplify/auth";
import {useDispatch} from "react-redux";
import {signInUser, signOutUser} from "./redux/user/user.actions";
import Footer from "./Components/Common/Footer";
import axios from "axios";
import {signInMember} from "./redux/member/member.actions";
import {getAdmins} from "./redux/admin/admin.actions";

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const {userId, signInDetails} = await getCurrentUser();
        const authUser = {
          id: userId,
          email: signInDetails.loginId,
        };
        userId && dispatch(signInUser(authUser));

        //check if member signed in by page reloading
        axios.get('/member')
          .then(members => {
            Object.entries(members.data).forEach(([key, member]) => {
              if (member.email === authUser.email.toString()) {

                const currentMember = {
                  id: member._id,
                  key: key,
                  lastName: member.lastName,
                  firstName: member.firstName,
                  suffix: member.suffix,
                  email: member.email
                }
                dispatch(signInMember({
                  id: currentMember.id,
                  email: currentMember.email
                }));
              }
            });
          });

      } catch (e) {
        // console.log(e)
        dispatch(signOutUser())
      }

      const arr=[]
      axios.get('/admin').then((result) => {
        Object.entries(result.data).forEach(([key, admin]) => {
         arr.push(admin.email)
        });
        dispatch(getAdmins(arr));
      })

    })();
  }, [dispatch]);


  return (
    <>
      <MainMenu/>
      <Footer/>
    </>

  );
}


export default App;
