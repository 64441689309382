
import  {useEffect, useState} from "react";

export const useWindowSize = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension(){
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return(() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])
  return (
    screenSize
  )
}