import React from "react";
import {Button, message} from 'antd';
import axios from "axios";
import {Message} from "semantic-ui-react";
import {useWindowSize} from "../../useWindowSize";

const ResidentCourse = () => {

  const handleClick = (e) => {

    message.info('redirecting to payment link').then(e => {
      axios.get('/resident-payment-link').then(res => {
        window.open(res.data[0].url)// the res.data[0].url is a link like -> 'https://buy.stripe.com/test_14kbK69KZ0P83TO4gj'
      })
    })
  }

  const {width} = useWindowSize();


  return (
    <>
      <div style={{maxWidth: 800, margin: "auto", marginTop: 75}}>


        <script async
                src={"https://js.stripe.com/v3/buy-button.js"}>
        </script>

        <div style={
          width > 600 ?
            {
              width: 600,
              margin: "auto",
              padding: 10,
              boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
              borderRadius: 6,
              border: '#f4f4f4 solid .5px'

            } :
            {
              width: width - 10,
              margin: "auto",

              boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)",
              borderRadius: 6,
              border: '#f4f4f4 solid .5px'
            }
        }>

          <Message info header={<h1>Annual Resident Course {}</h1>} content={
            <h5>Course Payment</h5>
          }/>
          <Button type={'primary'} onClick={handleClick} style={{backgroundColor: "#073495"}}>
            Pay Now
          </Button> <span style={{color: "#afafaf"}}>(You will be redirected to payment link)</span>

        </div>
      </div>
    </>
  );
}

export default ResidentCourse;