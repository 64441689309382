import {Card} from "antd";


const Home = () => {

  return(
    <div style={{top:0,left:0,right:0, position:"absolute", bottom:0}} >
    <div className={"center-screen"}>
      <div >
        <Card ><img  src="/Logo_400px.jpg" alt="Logo" width={300}/></Card>
      </div>
    </div></div>
  )
}
export default Home