import React, {useEffect, useState} from "react";
import axios from "axios";
// import { message} from "antd";
import LoaderOverLayAntD from "../../LoaderOverLayAntD";

const ListAdmins = () => {
  const [admin, setAdmin] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    async function fetchData() {
      setLoading(true)
      const admin = await axios.get('/admin');
      setAdmin(admin.data);
    }

    fetchData().then(() => {
      setLoading(false)
    });

  }, []);

  // const deleteAdmin = (id) => {
  //
  //   axios.post('/admin/delete', {
  //     id
  //   }).then(r => {
  //     message.success('admin successfully deleted ', 2);
  //   }).catch(e => {
  //     message.error('An Error has occurred')
  //   });
  // }

  return <>
    {loading && <LoaderOverLayAntD/>}
    {admin.map((admin, index) =>
      <div key={index.toString()}>

        <div style={{padding: 5, borderBottom: '1px solid #f4f4f4'}}>
          {/*  <Popconfirm*/}
          {/*    title="Delete admin?"*/}
          {/*    okText="Yes"*/}
          {/*    cancelText="No"*/}
          {/*    onConfirm={() => {deleteAdmin(admin._id)}}*/}
          {/*  >*/}
          {/*    <Button type="primary" size={'small'} danger>delete</Button>*/}
          {/*  </Popconfirm>*/}

          {admin.email !== 'pavlospapadonikolakis@yahoo.com' ?
            <span style={{marginLeft: 10}}>{admin.email}</span> : ''}

        </div>
      </div>
    )}

  </>


}
export default ListAdmins;